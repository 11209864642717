<template>
  <v-container
    id="desks"
    fluid
    tag="section"
    class="ma-0 pa-0"
    @click="clicked"
    style="height: 100%"
  >
    <v-row style="display: none">
      <v-col cols="12" md="12">
        <v-row>
          <v-col cols="12" md="4">
            <v-select
              :items="campusData"
              item-text="buildingName"
              item-value="id"
              v-model="campus"
              persistent-hint
              return-object
              label="Building*"
              @change="campusChange"
            ></v-select>
          </v-col>
          <v-col cols="12" md="4">
            <v-select
              :items="floorData"
              item-text="name"
              item-value="id"
              v-model="floor"
              persistent-hint
              return-object
              label="Floor*"
              @change="floorChange"
            ></v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row id="spaceContainer" class="ma-0 pa-0" style="height: 100%; background: #ffffff">
      <!--地图-->
      <v-col cols="12" md="8" ref="mapClass" id="mapClass" class="ma-0 pa-0">
        <v-row class="mapContent ma-0">
          <v-col cols="12" md="12" class="pa-0">
            <v-row class="ma-0">
              <v-col cols="12" md="6" class="pa-0">
                <div class="canvasHeader">
                  <v-btn
                    rounded
                    class="x-btn"
                    @click="refreshToday"
                    v-show="showTodayBtn"
                    tabindex="0"
                    >Today
                  </v-btn>
                  {{ startTime }}
                  &nbsp;To&nbsp;
                  {{ endTime }}
                  <!--{{ month }}-->
                  <!--{{ dateTitle }},-->
                  <!--{{ start }}&nbsp;to-->
                  <!--{{ end }}-->
                  <!--{{ headerTime }}-->
                </div>
              </v-col>
              <v-col cols="12" md="6" class="pa-0">
                <div class="canvasHeader">Current: {{ current }}</div>
              </v-col>
              <div class="colorCircleContainer" v-if="client == 'WarnerMedia'">
                <div class="oneCircle">
                  <p class="circle circle-bookable"></p>
                  Bookable
                </div>
                <div class="oneCircle">
                  <p class="circle circle-booked"></p>
                  Booked
                </div>
                <div class="oneCircle">
                  <p class="circle circle-vacant"></p>
                  Vacant
                </div>
                <div class="oneCircle">
                  <p class="circle circle-occupied"></p>
                  Occupied
                </div>
                <!-- <div class="oneCircle">
                  <p class="circle circle-crowded"></p>
                  Crowded
                </div> -->
                <div class="oneCircle">
                  <p class="circle circle-unavailable"></p>
                  Unavailable
                </div>
              </div>
              <div class="colorCircleContainer" v-else>
                <div class="oneCircle">
                  <p class="circle circle-bookable"></p>
                  <span v-if="client == 'DoorDash'">Available</span>
                  <span v-else>Bookable</span>
                </div>
                <div class="oneCircle">
                  <p class="circle circle-booked"></p>
                  Booked
                </div>
                <!--Applied Materials先隐藏这个Free for指示-->
                <div class="oneCircle" v-if="show15Logic">
                  <p class="circle circle-free"></p>
                  Free for < 15m
                </div>
                <div class="oneCircle">
                  <p class="circle circle-unavailable"></p>
                  Unavailable
                </div>
              </div>
            </v-row>
          </v-col>
          <v-col cols="2" md="2" v-show="showIcons" style="justify-content: center">
            <div class="static" v-show="client != 'NatWest' && client != 'DoorDash'" tabindex="0">
              <div class="percentage">{{ floorCapacity }}</div>
              <div class="static_title">
                Floor<br />
                Capacity
              </div>
            </div>
            <div class="static" tabindex="0" v-if="client != 'DoorDash'">
              <div class="percentage">{{ availableDesk }}</div>
              <div class="static_title">
                Available<br />
                Desks
              </div>
            </div>
            <div class="static" tabindex="0" v-if="client != 'DoorDash'">
              <div class="percentage">{{ desksBooked }}</div>
              <div class="static_title">
                Desks<br />
                Booked
              </div>
            </div>
            <div class="static">
              <!--<button @click="addMinutes">-->
              <!--  <img src="@/assets/images/map/add.png" class="calendar"/>-->
              <!--</button>-->
              <!--<button @click="minusMinutes">-->
              <!--  <img src="@/assets/images/map/reduce.png" class="calendar"/>-->
              <!--</button>-->
              <img
                src="@/assets/images/map/add.png"
                aria-label="forward thirty minutes"
                @click="addMinutes"
                @keyup.enter="addMinutes"
                class="calendar"
                tabindex="0"
                role="button"
              />
              <img
                src="@/assets/images/map/reduce.png"
                aria-label="back thirty minutes"
                @click="minusMinutes"
                @keyup.enter="minusMinutes"
                class="calendar"
                tabindex="0"
                role="button"
              />
              <div class="static_title">
                +/-<br />
                30m
              </div>
            </div>
            <div class="static" @click="showDateTime" @keyup.enter="showDateTime">
              <img
                src="@/assets/images/map/calendar_blue.png"
                aria-label="custom timeframe"
                class="calendar"
                tabindex="0"
                role="button"
              />
              <div class="static_title">
                Custom <br />
                Timeframe
              </div>
            </div>
            <div class="static" @click="select" @keyup.enter="select" aria-label="select floor">
              <img
                src="@/assets/images/map/floor_select.jpg"
                class="calendar"
                tabindex="0"
                role="button"
              />
              <div class="static_title">
                Select<br />
                Floor
              </div>
            </div>
          </v-col>
          <v-col
            cols="10"
            md="10"
            ref="floorMapBlock"
            id="floorMapBlock"
            style="position: relative"
            class="pa-0"
          >
            <canvas
              id="floorMap"
              ref="floorMap"
              :width="width"
              :height="height"
              tabindex="0"
            ></canvas>
            <div class="mapPan">
              <div class="static">
                <v-img
                  src="@/assets/images/map/jia.png"
                  aria-label="zoom In"
                  class="calendar"
                  @click="zoomIn"
                  tabindex="0"
                  @keyup.enter="zoomIn"
                ></v-img>
                <v-img
                  src="@/assets/images/map/jian.png"
                  aria-label="zoom Out"
                  class="calendar"
                  @click="zoomOut"
                  tabindex="0"
                  @keyup.enter="zoomOut"
                ></v-img>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row class="dateTimePicker ma-0 pa-0" v-if="showDate">
          <v-col cols="12" class="ma-0 pa-0 dateTimePicker-date">
            <v-btn @click="showDialogDate" class="btn1" rounded outlined>{{ btnText }}</v-btn>
          </v-col>
          <v-col cols="12" class="ma-0 pa-0 dateTimePicker-operator">
            <v-btn
              rounded
              class="btn2 ma-0"
              outlined
              color="#fff"
              @click="clickCancel"
              style="width: 400px"
              >Cancel
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <!--右侧详细信息-->
      <v-col cols="12" md="4" v-show="showSearch" class="ma-0 pa-0">
        <!--<router-view></router-view>-->
        <Search
          ref="searchComponent"
          :list="listData"
          :buildFloor="buildingsAndFloorsData"
          :showList="showList"
          :bfData="bfData"
          :defaultBfData="defaultBfData"
          v-on:changeSearchList="changeSearchList"
          v-on:changeShowList="changeShowList"
          v-on:clickCanlender="clickCanlender"
          v-on:backToSearchList="backToSearchList"
          v-on:acceptMap="acceptMap"
          v-on:refreshFilter="refreshFilter"
        ></Search>
      </v-col>
      <v-col cols="12" md="4" v-show="showDeskDetail" class="ma-0 pa-0">
        <deskBooking
          ref="deskBooking"
          v-on:backToSearchList="backToSearchList"
          v-on:clickCross="clickCross"
          v-on:changeDeskInfoToFalse="changeDeskInfoToFalse"
          v-on:changeBookTime="changeBookTime"
          v-on:jumpToSelectPa="jumpToSelectPa"
          v-on:cancelPa="cancelPa"
          :showDeskInfo="showDeskInfo"
          :data="deskItem"
          :participants="participants"
          :time="bookingTime"
          :deskShowBtn="deskShowBtn"
          v-show="showDeskItem"
          :isCustomTimeFrame="showTodayBtn"
        ></deskBooking>
      </v-col>
      <v-col cols="12" md="4" v-show="showSelectPa" class="ma-0 pa-0">
        <!--<selectPa-->
        <!--  ref="selectPa"-->
        <!--  v-on:backToBooking="backToBooking"-->
        <!--  v-on:addPa="addPa"-->
        <!--&gt;</selectPa>-->
        <selectPa
          ref="selectPa"
          v-on:backToBooking="backToBooking"
          v-on:addPa="addPa"
          :pa="participants"
        ></selectPa>
      </v-col>
    </v-row>
    <!--选择楼层-->
    <v-dialog v-model="selectFloor" max-width="290">
      <v-card class="dialog-window ma-0 pa-0">
        <div style="text-align: right; padding: 15px; padding-bottom: 0">
          <i
            class="iconfont icon-chahao"
            @click="selectFloor = false"
            style="cursor: pointer"
            tabindex="0"
            @keyup.enter="selectFloor = false"
          ></i>
        </div>
        <v-card-text>
          <div
            v-for="(d, index) in buildingsAndFloorsData"
            :key="'d' + index"
            class="buildingFloor"
          >
            <p class="buildingName" tabindex="0">{{ d.buildingName }}</p>
            <p
              v-for="(f, index) in d.floors"
              :key="'f' + index"
              class="floorName"
              :class="{ selectColor: selectFloorId === f.id }"
              @click="floorChange(d, f)"
              @keyup.enter="floorChange(d, f)"
              tabindex="0"
            >
              {{ f.name }}
              <v-icon dense color="blue darken-2" style="float: right" v-if="selectFloorId === f.id"
                >mdi-check
              </v-icon>
            </p>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--提示选择默认楼层-->
    <v-dialog v-model="notDefault" persistent max-width="290">
      <v-card>
        <v-card-title style="word-break: keep-all; word-wrap: break-word; overflow: hidden"
          >Please select your default location for booking spaces by choosing a campus, building and
          floor.
        </v-card-title>
        <v-card-actions>
          <v-btn color="green darken-1" text @click="selectDefault" style="margin: auto">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--选择默认楼层-->
    <v-dialog v-model="selectDefaultDialog" persistent max-width="290">
      <v-card class="dialog-window ma-0 pa-0">
        <div style="text-align: right; padding: 15px; padding-bottom: 0">
          <!--<i class="iconfont icon-chahao" @click="selectDefaultDialog=false" style="cursor: pointer;"></i>-->
        </div>
        <v-card-text style="height: calc(100% - 65px); overflow-y: scroll">
          <div
            v-for="(d, index) in buildingsAndFloorsData"
            :key="'bf' + index"
            class="buildingFloor"
          >
            <p class="buildingName">{{ d.buildingName }}</p>
            <!--<p v-for="f in d.floors" class="floorName" :class="{'x-btn':selectFloorId === f.id}"-->
            <p
              v-for="(f, index) in d.floors"
              :key="'floor' + index"
              class="floorName"
              @click="defaultChange(d, f)"
            >
              {{ f.name }}
              <v-icon dense color="blue darken-2" style="float: right" v-if="selectFloorId === f.id"
                >mdi-check
              </v-icon>
            </p>
            <!--<p v-for="f in d.floors" class="floorName" :class="{'selectColor':defaultFloorId === f.id}"-->
            <!--   @click="defaultChange(d.buildingName, f)">{{ f.name }}-->
            <!--  <v-icon dense color="blue darken-2" style="float: right;" v-if="defaultFloorId === f.id">mdi-check-->
            <!--  </v-icon>-->
            <!--</p>-->
          </div>
        </v-card-text>
        <v-card-actions style="position: absolute; bottom: 0; width: 100%">
          <v-btn color="green darken-1" text @click="saveDefault" style="margin: auto">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--选时间-->
    <v-dialog role="dialog" pa="5" v-model="dialogDate" persistent max-width="600px">
      <v-card style="height: 370px">
        <v-card-title>
          <span class="headline">Please select a date</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <!--Date-->
              <v-col cols="12" @keyup.enter="menuDialog = true">
                <!-- <v-menu
                  v-model="menuDialog"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  v-focus
                  tabindex="1"
                  ref="tt"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateText"
                      label="Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateDialog"
                    @input="menuDialog = false"
                    :allowed-dates="allowedDates"
                  ></v-date-picker>
                </v-menu> -->

              <v-menu v-model="menuDialog" :close-on-content-click="false">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateText"
                      label="Date"
                      prepend-icon="mdi-calendar"
                      v-on="on"
                      autocomplete="off"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateDialog"
                    no-title
                    @input="menuDialog = false"
                    :allowed-dates="allowedDates"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12">
                <Time
                  v-model="startTimeDialog"
                  format="hh:mm a"
                  :minute-interval="30"
                  manual-input
                  placeholder="Start Time"
                  :input-width="'100%'"
                  tabindex="2"
                ></Time>
              </v-col>
              <!--<v-col cols="12">-->
              <!--  <Time v-model="endTimeDialog"-->
              <!--        format="hh:mm a"-->
              <!--        :minute-interval="30"-->
              <!--        manual-input-->
              <!--        placeholder="End Time"-->
              <!--        :input-width="'100%'"-->
              <!--  ></Time>-->
              <!--</v-col>-->
            </v-row>
          </v-container>
        </v-card-text>
        <!--操作按钮-->
        <v-card-actions>
          <v-spacer></v-spacer>
          <span
            style="margin-right: 30px"
            class="dateBtn"
            tabindex="3"
            @click="dialogDate = false"
            @keyup.enter="dialogDate = false"
          >
            Close
          </span>
          <span class="dateBtn" tabindex="3" @click="clickSave" @keyup.enter="clickSave">
            Save
          </span>
          <!--<v-btn-->
          <!--  color="blue darken-1"-->
          <!--  text-->
          <!--  tabindex="1"-->
          <!--  @click="dialogDate = false"-->
          <!--&gt;-->
          <!--  Close-->
          <!--</v-btn>-->
          <!--<v-btn-->
          <!--  color="blue darken-1"-->
          <!--  text-->
          <!--  tabindex="1"-->
          <!--  @click="clickSave"-->
          <!--  @keyup.enter="clickSave"-->
          <!--&gt;-->
          <!--  Save-->
          <!--</v-btn>-->
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-alert v-model="showWarning" border="top" color="red lighten-2" dark>
      I'm an alert with a top border and red color
    </v-alert>
    <!--定时刷新-->
    <v-dialog v-model="showRefresh" max-width="290">
      <v-card>
        <v-card-title style="font-size: 18px"> Please Refresh</v-card-title>
        <v-card-text style="font-size: 16px">
          You've been away for a period of time. Please hit the refresh button to update the map's
          availability.
        </v-card-text>
        <v-card-actions style="display: flex; justify-content: center">
          <!--<v-spacer></v-spacer>-->
          <v-btn color="green" text @click="ok"> Refresh</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--checkIn pop up-->
    <v-dialog v-model="showCheckIn" max-width="450" content-class="checkInPopup" persistent>
      <v-card>
        <v-card-title class="title"> Check In to {{ checkInDeskName }}</v-card-title>
        <v-card-text>
          <div class="circle">
            Time to <br />
            Check in!
          </div>
        </v-card-text>
        <v-card-text class="checkInText">
          <div class="txt">
            <div style="height:30px"></div>
            <p>
              {{ checkInMsg }}
            </p>
          </div>
        </v-card-text>
        <v-card-actions class="btnContainer">
          <!--<v-spacer></v-spacer>-->
          <v-btn text @click="checkIn" class="checkInBtn"> Check In Now</v-btn>
          <v-btn text @click="cancelCheckIn" class="canCelBtn">
            Cancel Reservation
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--    checkIn result pop up-->
    <v-dialog v-model="showCheckInResult" max-width="450" content-class="checkInPopup" persistent>
      <v-card v-if="showCheckInSuccess">
        <v-card-title class="title"> Check In to {{ checkInDeskName }}</v-card-title>
        <v-card-text>
          <div class="circle">Success</div>
        </v-card-text>
        <v-card-text class="checkInText">
          You have successfully checked in to your reservation.
        </v-card-text>
        <v-card-actions class="btnContainer">
          <!--<v-spacer></v-spacer>-->
          <v-btn text @click="closeCheckInResult" class="checkInBtn">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else>
        <v-card-title class="title"> Check In to {{ checkInDeskName }}</v-card-title>
        <v-card-text>
          <div class="circle" style="background: gray">Cancelled</div>
        </v-card-text>
        <v-card-text class="checkInText">
          Your reservation has been cancelled. This space will now be available for others to book.
        </v-card-text>
        <v-card-actions class="btnContainer">
          <!--<v-spacer></v-spacer>-->
          <v-btn text @click="closeCheckInResult" class="checkInBtn">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
import Search from "../componentsHa/Search.vue";
import deskBooking from "../componentsHa/deskBooking.vue";
import selectPa from "../componentsHa/selectPa";
import Time from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import show15 from "@/util/mapShow15";
import showSensor from "@/util/sensor";

var canvas;
var num = 0.3;
export default {
  name: "Spaces",
  components: {
    Search,
    deskBooking,
    Time,
    selectPa
  },
  watch: {
    showRefresh(val) {
      if (val) {
        this.stopInterval();
      } else {
        this.refreshAll();
        if (!this.showTodayBtn) {
          this.sensorStatusEasy();
        }
      }
    },
    dateDialog(val) {
      console.log("newVal:", val);
      if (val == undefined) {
        return;
      }
      this.dateText = this.formatDate(val);
      console.log("dateText:", this.dateText);
    },
    showList(newVal, oldVal) {
      if (newVal) {
        this.showIcons = false;
      } else {
        this.showIcons = true;
      }
    },
    showSearch(newVal, oldVal) {
      if (newVal) {
        this.showDeskDetail = false;
        this.showSelectPa = false;
      }
    },
    showDeskDetail(newVal, oldVal) {
      if (newVal) {
        this.showSearch = false;
        this.showSelectPa = false;
      } else {
        this.$refs["deskBooking"].bookingDeletegate = {};
      }
    },
    showSelectPa(newVal, oldVal) {
      if (newVal) {
        this.showSearch = false;
        this.showDeskDetail = false;
      }
    },
    startTime(newVal, oldVal) {
      console.log("startTime:", newVal);
      let string = this.year + newVal;
      let d = new Date();
      d.setSeconds(0);
      let timezone = localStorage.getItem("timezone");
      let start;
      if (timezone) {
        console.log("1111111111111111");
        start = moment(d).tz(timezone);
        console.log("start123", start);
      } else {
        console.log("2222222222222222");
        start = moment(d);
      }
      console.log("start!!!!!!!!!!!!!!!!!", start);
      const remainder = start.minute() % 30; //距离前面整（半）点的分钟
      console.log("remainder", remainder);
      let string2 = moment(start)
        .add(-remainder, "minutes")
        .format("YYYYMMM D, h:mm a");
      this.startTimeDialog = moment(start)
        .add(-remainder, "minutes")
        .format("hh:mm a");
      console.log(" this.startTimeDialog:", this.startTimeDialog);

      console.log("string2", string2);
      if (string == string2 && this.showDate == false) {
        this.showTodayBtn = false;
      } else {
        this.showTodayBtn = true;
      }
    },
    showTodayBtn(newVal, oldVal) {
      if (this.client == "WarnerMedia") {
        let that = this;
        if (newVal == false) {
          that.sensorIds = [];
          that.roomData.map(item => {
            that.sensorIds.push(item.sensorId);
          });
          that.sensorStatus(that.sensorIds);
        }
      }
    }
    // menuDialog(newVal,oldVal){
    //   console.log("====menuDialog =====",newVal)
    //   console.log("====this.$refs[\"tt\"] =====",this.$refs["tt"])
    //   console.log("focus",this.$refs["tt"].focus)
    //   if(newVal){
    //     this.$refs["tt"].tabindex=0;
    //   }
    // }
  },
  data() {
    return {
      advanceBookingLimit: null,
      showRoomButton: true, //false 则不请求room数据
      showDeskButton: true, //false 则不请求room数据
      checkInDesk: null,
      showCheckInSuccess: true, //true显示Success false显示cancel
      showCheckInResult: false, //check in结果弹窗
      checkInDeskName: "", //check in desk的名子
      checkInMsg: "", //checkIn提示语句
      campusName: "",
      showCheckIn: false, //显示checkIn pop up
      timezone: "",
      show15Logic: true,
      deskShowBtn: false, //默认desk都是订一天的，当该属性为true时，则desk也显示按钮组
      noFloor: true, //默认所有的building下面都没有floor
      keydownIndex: 0, //用键盘控制map时候的下标
      client: "",
      selectFloorId: "",
      selectBuildingId: "",
      showRefresh: false,
      bfData: {}, //用户当前选择的building和floor（传给search组件）
      defaultBfData: {}, //用户默认的building和floor（传给search组件）
      pa: [], //选择与会人员时默认已存在的人
      currentSearchFilter: {}, //当前search List的筛选条件
      showWarning: false,
      showTodayBtn: false,
      notDefault: false,
      selectDefaultDialog: false,
      participants: [], //deskBooking组件展示的与会人员
      showSearch: true, //右侧展示search页面
      showDeskDetail: false, //右侧展示预定的详情页面
      showSelectPa: false, //右侧展示与人人员搜索

      currentItem: {}, //当前地图选中的desk或则room
      state: "", //room的状态 绿色（后面半个小时可预订） 黄色（后面半个小时已被预定） 红色（当前已经被预定）
      endTimePlusTwoUnix: 0, //结束时间后两个小时的时间戳
      showDeskInfo: false, //deskBooking显示的页面，true 显示按钮组 false 显示book it
      showIcons: true, //地图左侧图标显示
      bookingTime: {}, //deskbooking组件预定的时间
      stringStart: "", //开始日期+开始时间（自定义）
      stringEnd: "", //结束日期+结束时间（自定义）
      btnText: "Custom Timeframe ", //自定义日期按钮的text
      dateDialog: "", //开始日期（自定义）
      dateText: "", //开始日期（文本）
      startTimeDialog: "09:00 am", //开始时间（自定义）
      endTimeDialog: "", //结束时间（自定义）
      menuDialog: false, //开始日期菜单（自定义）
      menuStartDialog: false, //开始时间菜单（自定义）
      menuEndDialog: false, //结束时间菜单（自定义）
      dateFormatted: "",
      dialogDate: false,
      worker: null,
      year: "", //默认当前年份
      month: "", //title开始月份
      dateTitle: "", //title日期
      start: "", //title开始时间
      end: "", //title结束时间
      showDate: false,
      selectFloor: false,
      buildingsAndFloorsData: [],
      campusData: [],
      campus: "",
      floorData: [], //楼层下的所有room和desk
      roomData: [], //楼层下的所有room
      deskData: [], //楼层下的所有desk
      floor: "",
      floorsData: [],
      floorsDataActive: [], //desk和room中属性是active的
      floorsDataActiveAndHasState: [], //desk和room中属性是active的并且resourceState中是有他们的
      desksStateData: [],
      height: 500,
      width: 400,
      imageW: 0,
      imageH: 0,
      canvasW: 0,
      canvasH: 0,
      startTime: "",
      endTime: "",
      deskItem: {},
      showDeskItem: false,
      // defaultFloorId: '',
      defaultBuildingId: "",
      headerTime: "",
      current: "",
      showList: false, //true 展示Serch界面 false展示Search list
      menu1: false, //日期选择
      date: "",
      roomResourceIdString: "", //所有房间的resouceId字符串拼接
      roomsStateData: [], //room的booking状态
      listData: [], //点击search后显示筛选完的list
      floorCapacity: "0%",
      availableDesk: "0",
      desksBooked: "0",
      // 长时间未点击页面判断
      timeout: null,
      sensorIds: [] //room的传感器id集合
    };
  },
  methods: {
    cancelSeatReturn() {
      let reservationId = this.checkInDesk.reservation_id;
      return this.$axios({
        method:
          this.client == "HPE" || this.client == "HPE Campus" || this.client == "CX Campus"
            ? "post"
            : "get",
        url:
          this.client == "HPE" || this.client == "HPE Campus" || this.client == "CX Campus"
            ? "/m/v2/desk/cancelSeat?reservationId=" + reservationId
            : "/m/desk/cancelSeat", //post写法不应该这样，后台代码合并有问题，暂时先兼容一下后台
        data: {
          meetingId: localStorage.meetingIdMap,
          reservationId: reservationId
        }
      });
    },
    checkInReturn(id) {
      return this.$axios({
        method: "post",
        url: "/m/spaces/checkIn",
        // method: (this.client == 'HPE' || this.client == 'HPE Campus' || this.client == 'CX Campus') ? 'post' : 'get',
        // url: (this.client == 'HPE' || this.client == 'HPE Campus' || this.client == 'CX Campus') ? '/m/v2/spaces/checkIn' : '/m/spaces/checkIn',
        data: {
          reservationId: id,
          type: "desk"
        }
      });
    },
    // 查询多个desk
    getUserSeatsReturn() {
      return this.$axios({
        method:
          this.client == "HPE" || this.client == "HPE Campus" || this.client == "CX Campus"
            ? "post"
            : "get",
        url:
          this.client == "HPE" || this.client == "HPE Campus" || this.client == "CX Campus"
            ? "/m/v2/desk/getUserSeats"
            : "/m/desk/getUserSeats",
        data: {
          email: localStorage.userEmail,
          meetingId: localStorage.meetingIdMap
        }
      });
    },
    // 查询单个desk
    getOneUserSeatReturn() {
      return this.$axios({
        method:
          this.client == "HPE" || this.client == "HPE Campus" || this.client == "CX Campus"
            ? "post"
            : "get",
        url:
          this.client == "HPE" || this.client == "HPE Campus" || this.client == "CX Campus"
            ? "/m/v2/desk/getUserSeat"
            : "/m/desk/getUserSeat",
        data: {
          reservationId: localStorage.checkInDeskId,
          meetingId: localStorage.meetingIdMap
        }
      });
    },
    checkIn() {
      this.bus.$emit("loading", true);
      let id = this.checkInDesk.reservation_id;
      this.checkInReturn(id)
        .then(res => {
          this.bus.$emit("loading", false);
          this.showCheckInSuccess = true;
          this.showCheckInResult = true;
        })
        .catch(err => {
          this.bus.$emit("loading", false);
        });
    },
    cancelCheckIn() {
      this.bus.$emit("loading", true);
      let that = this;
      this.$confirm({
        title: "Confirmation",
        message: "Are you sure you would like to cancel this reservation?",
        show: true,
        showTwoBtn: true,
        okMsg: "Confirm",
        cancelMsg: "Go Back"
      }).then(res => {
        that
          .cancelSeatReturn()
          .then(res => {
            that.bus.$emit("loading", false);
            console.log("res````", res);
            if (res.data.result == "failed") {
              that.$confirm({
                title: "Confirmation",
                message: res.data.message,
                show: true
              });
            } else {
              that.showCheckInSuccess = false;
              that.showCheckInResult = true;
            }
          })
          .catch(err => {
            that.bus.$emit("loading", false);
          });
      });
    },
    closeCheckInResult() {
      this.showCheckInResult = false;
      this.showCheckIn = false;
      localStorage.removeItem("checkInDeskId");
    },
    ok() {
      this.showRefresh = false;
    },
    clicked() {
      if (this.timeout) {
        this.stopInterval();
        this.startInterval();
      }
    },
    formatDate(date) {
      console.log("formatDate:", date);
      if (!date) return null;
      let st = moment(date, "YYYY-MM-DD").format("MMM D, YYYY");
      return st;
    },
    parseDate(date) {
      this.$nextTick(() => {
        if (!date) return null;
        let st = moment(date, "MMM D, YYYY").format("YYYY-MM-DD");
        return st;
      });
    },
    // 页面滚到最底部
    toBottom() {
      let height = document.body.clientHeight;
      window.scroll({ top: height, left: 0, behavior: "smooth" });
    },
    fn(ev) {
      ev.preventDefault();
    },
    // allowedDates: (val) => Date.parse(val) > Date.now() - 8.64e7,
    allowedDates(val) {
      console.log("val:", val);
      let d = new Date();
      d.setSeconds(0);
      let unix;
      let days; //每个日期按钮距离当天的日子
      let startOfDayUnix; //当天0点
      let valUnix; //每个日期按钮的0点
      if (this.timezone) {
        valUnix = moment.tz(val, this.timezone).format("x");
        startOfDayUnix = moment
          .tz(d, this.timezone)
          .startOf("day")
          .format("x");
        unix = moment.tz(val, this.timezone).format("x");
        days = moment.tz(unix, "x", this.timezone).diff(moment(startOfDayUnix, "x"), "days");
      } else {
        unix = moment(val).format("x");
        days = moment(unix, "x").diff(moment(Date.now() - 8.64e7, "x"), "days");
      }
      // console.log("valUnix:", valUnix);
      // console.log("days:", days);
      if (this.advanceBookingLimit) {
        if (this.timezone) {
          return valUnix >= startOfDayUnix && days >= 0 && days <= this.advanceBookingLimit;
        } else {
          return (
            Date.parse(val) > Date.now() - 8.64e7 && days >= 0 && days <= this.advanceBookingLimit
          );
        }
      } else {
        if (this.timezone) {
          return valUnix >= startOfDayUnix;
        } else {
          return Date.parse(val) > Date.now() - 8.64e7;
        }
      }
    },
    refreshToday() {
      this.showTodayBtn = false;
      this.showDate = false;
      this.dialogDate = false;
      this.menuDialog = false;
      this.dateDialog = "";
      this.btnText = "Custom Timeframe ";
      // if (this.client == "Clorox") {
      //   this.startTimeDialog = "hh:mm a";
      // } else {
      //   this.startTimeDialog = "09:00 am";
      // }
      this.endTimeDialog = "";
      this.showSearch = true;
      this.refreshCurrentDate();
      this.refreshAll();
      if (window.gtag) {
        window.gtag("event", "Space", {
          event_category: "Today click",
          event_label: "today",
          send_to: localStorage.getItem("googleAnaId") || "",
          dimension1: localStorage.getItem("campusName") || ""
        });
      }

      // this.$router.go(0);
    },
    // 获取用户在当前campus的默认building和floor
    // getUserLocation() {
    //   let that = this;
    //   return this.$axios({
    //     method: 'GET',
    //     url: 'm/campus/userDefaultLocation',
    //     params: {
    //       meetingId: localStorage.meetingIdMap,
    //       token: localStorage.token
    //     }
    //   })
    // },
    getUserLocation() {
      let that = this;
      this.$axios({
        method: "GET",
        url: "m/campus/userDefaultLocation",
        data: {
          meetingId: localStorage.meetingIdMap
          // token: localStorage.token
        }
      })
        .then(res => {
          let bId = res.data.data.defaultBuilding;
          let fId = res.data.data.defaultFloor;
          let length = that.buildingsAndFloorsData.length;
          let hasDefaultBuilding = false;
          let hasDefaultFloor = false;
          for (let i = 0; i < length; i++) {
            let item = that.buildingsAndFloorsData[i];
            if (item.id == bId) {
              hasDefaultBuilding = true;
              that.buildingName = item.buildingName;
              let length = item.floors.length;
              for (let i = 0; i < length; i++) {
                let jtem = item.floors[i];
                if (jtem.id == fId) {
                  hasDefaultFloor = true;
                  that.floorName = jtem.name;
                  that.floorChange(item, jtem);
                  that.bfData = {
                    buildingName: item.buildingName,
                    floorName: jtem.name,
                    currentFloor: jtem
                  };
                  that.defaultBfData = {
                    buildingName: item.buildingName,
                    floorName: jtem.name,
                    currentFloor: jtem
                  };
                  break;
                }
              }
              break;
            }
          }
          console.log("hasDefaultBuilding:", hasDefaultBuilding);
          console.log("hasDefaultFloor:", hasDefaultFloor);
          if (hasDefaultBuilding && hasDefaultFloor) {
            this.notDefault = false;
          } else {
            this.notDefault = true;
          }
          this.bus.$emit("loading", false);
        })
        .catch(err => {
          this.bus.$emit("loading", false);
          console.log(err);
        });
    },
    // deskbooking删除与会人员
    cancelPa(data) {
      let length = this.participants.length;
      for (let i = 0; i < length; i++) {
        let item = this.participants[i];
        if (data.id == item.id) {
          this.participants.splice(i, 1);
          break;
        }
      }
    },
    // 确定完与会人员点提交回到deskbooking并展示数据
    addPa(data) {
      // 去重
      if (this.participants.length == 0) {
        this.participants = data;
      } else {
        this.participants.map(item => {
          let length = data.length;
          for (let i = 0; i < length; i++) {
            if (item.email == data[i].email) {
              data.splice(i, 1);
              break;
            }
          }
        });
        this.participants = this.participants.concat(data);
      }
      console.log("2222222:", this.participants);
      this.showDeskDetail = true;
    },
    // 返回到booking详情页面
    backToBooking() {
      this.showDeskDetail = true;
    },
    // 切换至selectPa页面
    jumpToSelectPa(data) {
      console.log("切换至selectPa页面:", data);
      this.pa = data;
      // this.participants = data;
      this.showSelectPa = true;
    },
    // deskbooking显示book it
    changeDeskInfoToFalse(data) {
      this.showDeskInfo = false;
    },
    // 改变deskbooking book it显示的时间
    changeBookTime(data) {
      console.log("==========changeBookTime==========", data);
      this.bookingTime = {};
      this.bookingTime.start = data.start;
      this.bookingTime.end = data.end;
      console.log("111231231231231231", moment(data).unix());
      // console.log("this.bookingTime:", this.bookingTime);
    },
    // 刷新标题时间为当前时间(前后半小时,例：2：16则显示2：00-2：30， 2：35则显示2：30-3：00)
    refreshCurrentDate() {
      let d = new Date();
      d.setSeconds(0);
      this.year = d.getFullYear();
      let timezone = localStorage.getItem("timezone");
      let start;
      if (timezone) {
        start = moment(d).tz(timezone);
      } else {
        start = moment(d);
      }
      const remainder = start.minute() % 30; //距离前面整（半）点的分钟
      const remainder2 = 30 - (start.minute() % 30); //距离后面整（半）点的分钟
      // this.startTime = moment.tz(start, timezone).add(-remainder, "minutes").format("MMM D, h:mm a");
      this.startTime = moment(start)
        .add(-remainder, "minutes")
        .format("MMM D, h:mm a");
      this.endTime = moment(start)
        .add(remainder2, "minutes")
        .format("MMM D, h:mm a");
    },
    // 自定义日期点击cancel
    clickCancel(refreshAll = true) {
      this.showTodayBtn = false;
      this.showDate = false;
      this.dateDialog = "";
      this.btnText = "Custom Timeframe ";
      // if (this.client == "Clorox") {
      //   this.startTimeDialog = "hh:mm a";
      // } else {
      //   this.startTimeDialog = "09:00 am";
      // }
      this.refreshCurrentDate();
      if (refreshAll) {
        this.refreshAll();
      }
      if (!this.showTodayBtn) {
        this.sensorStatusEasy();
      }
    },
    // 自定义日期查看desk状态
    seeAvailability() {
      let that = this;
      let timezone = localStorage.getItem("timezone");
      if (timezone) {
        this.startTime = moment
          .tz(this.stringStart, "YYYY-MM-DDhh:mm a", timezone)
          .format("MMM D, h:mm a");
        this.endTime = moment
          .tz(this.stringEnd, "YYYY-MM-DDhh:mm a", timezone)
          .format("MMM D, h:mm a");
      } else {
        this.startTime = moment(this.stringStart, "YYYY-MM-DDhh:mm a").format("MMM D, h:mm a");
        this.endTime = moment(this.stringEnd, "YYYY-MM-DDhh:mm a").format("MMM D, h:mm a");
      }
      console.log('stringStart:',this.stringStart);
      console.log('stringEnd:',this.stringEnd);
      let startUnix;
      let endUnixDesk;
      let endUnix;
      if (timezone) {
        startUnix = moment.tz(this.stringStart, "YYYY-MM-DDhh:mm a",timezone).unix();
        endUnixDesk = moment
          .tz(this.stringEnd, "YYYY-MM-DDhh:mm a", timezone)
          .endOf("day")
          .unix();
        endUnix = moment
          .tz(this.stringEnd, "YYYY-MM-DDhh:mm a", timezone)
          .add(2, "hours")
          .unix();
      } else {
        startUnix = moment(this.stringStart, "YYYY-MM-DDhh:mm a").unix();
        endUnixDesk = moment(this.stringEnd, "YYYY-MM-DDhh:mm a")
          .endOf("day")
          .unix();
        endUnix = moment(this.stringEnd, "YYYY-MM-DDhh:mm a")
          .add(2, "hours")
          .unix();
      }
      // if (timezone) {
      //   startUnix = moment.tz(this.startTime, "MMM D, h:mm a", timezone).unix();
      //   endUnixDesk = moment
      //     .tz(this.endTime, "MMM D, h:mm a", timezone)
      //     .endOf("day")
      //     .unix();
      //   endUnix = moment
      //     .tz(this.endTime, "MMM D, h:mm a", timezone)
      //     .add(2, "hours")
      //     .unix();
      // } else {
      //   startUnix = moment(this.startTime, "MMM D, h:mm a").unix();
      //   endUnixDesk = moment(this.endTime, "MMM D, h:mm a")
      //     .endOf("day")
      //     .unix();
      //   endUnix = moment(this.endTime, "MMM D, h:mm a")
      //     .add(2, "hours")
      //     .unix();
      // }
      this.clearPath();
      this.bus.$emit("loading", true);
      let fun1 = function() {
        return new Promise((resolve, reject) => {
          let freeBusy = that.freeBusyReturn(startUnix, endUnix);
          resolve(freeBusy);
        });
      };
      let fun2 = function() {
        return new Promise((resolve, reject) => {
          let seatAvail = that.getSeatsAvaiableReturn(startUnix, endUnixDesk);
          resolve(seatAvail);
        });
      };
      let run = function() {
        Promise.allSettled([fun1(), fun2()])
          .then(res => {
            if (res[0].status === "fulfilled" && res[0].value) {
              that.roomsStateData = res[0].value.data.data;
              console.log("roomsStateData:", that.roomsStateData);
            } else {
              that.roomsStateData = [];
            }
            that.drawRoom();
            if (res[1].status === "fulfilled" && res[1].value) {
              // that.desksStateData = res[1].value.data;
              that.desksStateData = res[1].value.data.data;
              console.log("desksStateData:", that.desksStateData);
              let arr = [];
              let length = that.deskData.length;
              let length2 = that.desksStateData.length;
              console.log("length2:", length2);
              let deskDataHavePoints = []; ////用来当Floor capacity的分母
              for (let i = 0; i < length; i++) {
                let item = that.deskData[i];
                if (item.points.length > 0 && item.resourceId) {
                  deskDataHavePoints.push(item);
                }
                for (let j = 0; j < length2; j++) {
                  let jtem = that.desksStateData[j];
                  if (
                    item.resourceId == jtem.resourceId &&
                    jtem.active == true &&
                    item.points.length > 0
                  ) {
                    arr.push(jtem);
                    that.desksStateData[j].points = item.points; //给deskState加个points属性，因为左上角的available统计的前提是points不为空
                    break;
                  }
                }
              }
              that.drawDesk();
              let green = 0;
              let red = 0;
              let active = 0;
              let inActive = 0;
              that.desksStateData.map(item => {
                if (item.active) {
                  active += 1;
                  if (item.busy) {
                    red += 1;
                  } else {
                    if (item.points.length > 0) {
                      green += 1;
                    }
                    // green += 1;
                  }
                } else {
                  inActive += 1;
                }
              });

              // let point = (green + red) / (green + red + inActive);
              // let point = arr.length / that.deskData.length;
              let point = arr.length / deskDataHavePoints.length;

              if (isNaN(point)) {
                that.floorCapacity = "0%";
              } else {
                if (point == 1) {
                  that.floorCapacity = "100%";
                } else {
                  that.floorCapacity = Number(point * 100).toFixed(1) + "%";
                }
              }

              that.availableDesk = green;
              that.desksBooked = red;
              // return true;
            } else if (res[1].status === "fulfilled" && res[1].value == undefined) {
              that.floorCapacity = "0%";
              that.availableDesk = 0;
              that.desksBooked = 0;
            }
            that.bus.$emit("loading", false);
            //开始记录用户未操作时间
            that.stopInterval();
            that.startInterval();
          })
          .catch(err => {
            that.bus.$emit("loading", false);
            console.log(err);
          });
      };
      run();
    },
    // changeCTime() {
    //   console.log("Time:", this.startTimeDialog)
    //   let hour = this.startTimeDialog.substring(0, 2);
    //   let minute = this.startTimeDialog.substring(3, 5);
    //   let amOrpm = this.startTimeDialog.substring(6, this.startTimeDialog.length);
    //   console.log("hour:", hour)
    //   console.log("minute:", minute)
    //   console.log("amOrpm:", amOrpm)
    //   if(minute=="mm"){
    //     minute = "00";
    //   }
    //   if(amOrpm=="a"){
    //     amOrpm = "am";
    //   }
    //   if(amOrpm=="p"){
    //     amOrpm = "pm";
    //   }
    //   this.startTimeDialog = hour + ":" + minute + ":" + " " + amOrpm;
    //   console.log("Time2:",this.startTimeDialog)
    //
    // },
    // 自定义日期时间点击save
    clickSave() {
      console.log("dateDialog:", this.dateDialog); //2022-08-10
      this.year = moment(this.dateDialog,"YYYY-MM-DD").format("YYYY");
      let start = moment(this.startTimeDialog, "hh：mm a").unix();
      let d = new Date();
      d.setSeconds(0);
      // let year = d.getFullYear();
      // let startTimeString = this.startTime + year;
      let startTimeString = this.startTime + this.year;
      console.log('this.year:',this.year);
      let stUnix;
      let startTimezone;
      let dateToday;
      if (this.timezone) {
        stUnix = moment.tz(startTimeString, "MMM D, h:mm aYYYY", this.timezone).unix();
        startTimezone = moment.tz(this.startTimeDialog, "hh：mm a", this.timezone).unix();
        dateToday = moment.tz(d, this.timezone).format("YYYY-MM-DD");
      } else {
        stUnix = moment(startTimeString, "MMM D, h:mm aYYYY").unix();
        startTimezone = moment(this.startTimeDialog, "hh：mm a").unix();
        dateToday = moment(d).format("YYYY-MM-DD");
      }
      console.log("dateToday:", dateToday);
      console.log("startTimezone:", startTimezone);
      console.log("stUnix:", stUnix);
      let isSameDay = this.dateDialog == dateToday ? true : false;
      if (isSameDay && startTimezone < stUnix) {
        this.$confirm({
          title: "Confirmation",
          message: "Start time cannot be less than the current time.",
          show: true
        });
        return;
      }
      this.showTodayBtn = true;
      console.log("start");
      console.log("start--------------", start);
      console.log("startTimezone--------------", startTimezone);
      // let end = moment(this.endTimeDialog,"hh：mm a").unix();
      let rule = isNaN(moment(this.dateDialog).unix());
      let rule2 = isNaN(start);
      let ruleAll = rule & rule2;
      if (this.dateDialog == "" || this.startTimeDialog == "") {
        this.$confirm({
          title: "Confirmation",
          message: "Please fill in the date and time",
          show: true
        });
      } else if (ruleAll) {
        this.$confirm({
          title: "Confirmation",
          message: "Incorrect format.",
          show: true
        });
      } else {
        this.dialogDate = false;
        this.showSearch = true; //点击search的时候把右边界面换回到最开始的筛选页面 2020/12/20
        this.stringStart = this.dateDialog + this.startTimeDialog;
        this.stringEnd = moment(this.stringStart, "YYYY-MM-DDhh:mm a")
          .add(30, "minutes")
          .format("YYYY-MM-DDhh:mm a");
        let date = moment(this.stringEnd, "YYYY-MM-DDhh:mm a").format("MMM D");
        let startTime = moment(this.stringStart, "YYYY-MM-DDhh:mm a").format("hh:mm a");
        let endTime = moment(this.stringStart, "YYYY-MM-DDhh:mm a")
          .add(30, "minutes")
          .format("hh:mm a");
        this.btnText = date + ", " + startTime + " To " + endTime;
      }
      this.seeAvailability();
    },
    // 加30分钟
    addMinutes() {
      // this.start = moment(this.start, "hh:mm a").add(30, "minutes").format("h:mm a");
      // this.end = moment(this.end, "hh:mm a").add(30, "minutes").format("h:mm a");
      // let string = "";
      // let stringEnd = "";
      // string = this.year + this.month + this.dateTitle + this.start;
      // stringEnd = this.year + this.month + this.dateTitle + this.end;
      // let startUnix = moment(string, "YYYYMMMDDhh:mm a").unix();
      // let endUnix = moment(stringEnd, "YYYYMMMDDhh:mm a").unix();
      this.currentItem = {};
      this.removeBluePath();
      // this.showDeskDetail = false;
      this.showSearch = true;
      this.showList = false;
      this.startTime = moment(this.startTime, "MMM D, h:mm a")
        .add(30, "minutes")
        .format("MMM D, h:mm a");
      this.endTime = moment(this.endTime, "MMM D, h:mm a")
        .add(30, "minutes")
        .format("MMM D, h:mm a");
      this.refreshAll();
      if (window.gtag) {
        window.gtag("event", "Space ", {
          event_category: "Space add 30minute",
          event_label: "add",
          send_to: localStorage.getItem("googleAnaId") || "",
          dimension1: localStorage.getItem("campusName") || ""
        });
      }
    },
    // 减30分钟
    minusMinutes() {
      // this.start = moment(this.start, "hh:mm a").add(-30, "minutes").format("h:mm a");
      // this.end = moment(this.end, "hh:mm a").add(-30, "minutes").format("h:mm a");
      // let string = "";
      // let stringEnd = "";
      // string = this.year + this.month + this.dateTitle + this.start;
      // stringEnd = this.year + this.month + this.dateTitle + this.end;
      // let startUnix = moment(string, "YYYYMMMDDhh:mm a").unix();
      // let endUnix = moment(stringEnd, "YYYYMMMDDhh:mm a").unix();

      let d = new Date();
      d.setSeconds(0);
      let year = d.getFullYear();
      let startTimeString = this.startTime + year;
      const remainder = moment(d).minute() % 30; //距离前面整（半）点的分钟
      let stUnix; //左上角开始时间
      let currentUnix; //当前时间取（半）整
      if (this.timezone) {
        currentUnix = moment
          .tz(d, this.timezone)
          .add(-remainder, "minutes")
          .unix();
        stUnix = moment.tz(startTimeString, "MMM D, h:mm aYYYY", this.timezone).unix();
      } else {
        currentUnix = moment(d)
          .add(-remainder, "minutes")
          .unix();
        stUnix = moment.tz(startTimeString, "MMM D, h:mm aYYYY", this.timezone).unix();
      }
      console.log("currentUnix:", currentUnix);
      console.log("stUnix:", stUnix);
      if (stUnix <= currentUnix) {
        this.$confirm({
          title: "Confirmation",
          message: "Start time cannot be less than the current time.",
          show: true
        });
        return;
      }

      this.currentItem = {};
      this.removeBluePath();
      // this.showDeskDetail = false;
      this.showSearch = true;
      this.showList = false;
      this.startTime = moment(this.startTime, "MMM D, h:mm a")
        .add(-30, "minutes")
        .format("MMM D, h:mm a");
      this.endTime = moment(this.endTime, "MMM D, h:mm a")
        .add(-30, "minutes")
        .format("MMM D, h:mm a");
      this.refreshAll();
      if (window.gtag) {
        window.gtag("event", "Space ", {
          event_category: "Space miner 30minute",
          event_label: "miner",
          send_to: localStorage.getItem("googleAnaId") || "",
          dimension1: localStorage.getItem("campusName") || ""
        });
      }
    },
    changeSearchList(filter, isStartEndOfDate = false) {
      console.log("filter123:", filter);
      console.log("startTime", this.startTime);
      console.log("endTime", this.endTime);
      console.log("this.showDate", this.showDate);
      let that = this;
      if (this.showDate) {
        this.clickCancel(false);
      }
      if (!isStartEndOfDate) {
        let d = new Date();
        d.setSeconds(0);
        let year = d.getFullYear();
        console.log("year:", year);
        let startTimeString = this.startTime + year;
        console.log(startTimeString);
        let stUnix;
        if (this.timezone) {
          stUnix = moment.tz(startTimeString, "MMM D, h:mm aYYYY", this.timezone).unix();
        } else {
          stUnix = moment(startTimeString, "MMM D, h:mm aYYYY").unix();
        }
        console.log("stUnix", stUnix);
        console.log("filter.start", filter.start);
        console.log("filter.end", filter.end);
        console.log("判断", filter.start < stUnix);
        console.log("判断2", filter.end < stUnix);
        // 开始时间和结束时间都不可以小于当前时间
        if (filter.start < stUnix || filter.end < stUnix) {
          this.$confirm({
            title: "Confirmation",
            message: "Start time or end time cannot be less than the current time.",
            show: true
          });
          this.showList = false;
          return;
        }
        this.showList = true;
      }
      this.showList = true;

      this.currentSearchFilter = filter; //当前搜索条件
      this.bus.$emit("loading", true);
      let timezone = localStorage.getItem("timezone");
      if (timezone) {
        this.startTime = moment(filter.start, "X")
          .tz(timezone)
          .format("MMM D, h:mm a");
        this.endTime = moment(filter.end, "X")
          .tz(timezone)
          .format("MMM D, h:mm a");
      } else {
        this.startTime = moment(filter.start, "X").format("MMM D, h:mm a");
        this.endTime = moment(filter.end, "X").format("MMM D, h:mm a");
      }

      // this.startTime = moment(filter.start, "X").format("MMM D, h:mm a");
      // this.endTime = moment(filter.end, "X").format("MMM D, h:mm a");

      console.log("this.startTime", this.startTime);
      console.log("this.endTime", this.endTime);
      // this.startTime = moment(filter.start, "X").format("MMM D, h:mm a");
      // this.endTime = moment(filter.end, "X").format("MMM D, h:mm a");

      let endTimePlusTwoUnix = moment(filter.end, "X")
        .add(2, "hours")
        .unix();
      console.log("endTime:", this.endTime);
      console.log("endTimePlusTwoUnix:", endTimePlusTwoUnix);
      // console.log('floorsData:', this.floorsData);
      let arr = [];
      let searchListArr = [];
      // 清空画布上所有的desk和room
      let objects = canvas.getObjects("polygon");
      for (let i in objects) {
        canvas.remove(objects[i]);
      }
      canvas.renderAll();
      if (filter.type == "room") {
        //获取当前选中时间room的状态并在画布上渲染
        let startUnix;
        if (timezone) {
          startUnix = moment(filter.start, "X")
            .tz(timezone)
            .unix();
        } else {
          startUnix = filter.start;
        }
        console.log("startUnix::::::", startUnix);
        this.freeBusyReturn(startUnix, endTimePlusTwoUnix)
          .then(res => {
            console.log("走111", res);
            if (res == null) {
              this.listData = [];
              this.bus.$emit("loading", false);
              return;
            }
            this.bus.$emit("loading", false);
            this.roomsStateData = res.data.data;
            console.log("---------roomsStateData:", this.roomsStateData);
            console.log("---------roomData:", this.roomData);
            //右侧显示符合筛选条件的room
            this.roomsStateData.map((item, index) => {
              // 把roomData里的设备featureInstances加进roomsStateData
              let length = this.roomData.length;
              for (let i = 0; i < length; i++) {
                let room = this.roomData[i];
                if (item.resourceId == room.resourceId || item.resourceEmail == room.resourceId) {
                  item.active = room.active; //把roomData的active状态赋给roomStateData
                  item.featureInstances = room.featureInstances;
                  item.pointArrXY = [];
                  for (let i = 0; i < room.points.length; i++) {
                    let newI = room.points[i].split(",");
                    let obj = {};
                    if (!isNaN(newI[0]) && !isNaN(newI[1])) {
                      obj.x = newI[0];
                      obj.y = newI[1];
                      item.pointArrXY.push(obj);
                    }
                  }
                  break;
                }
              }
              filter.capacity == 2 ? (filter.capacity = 1) : (filter.capacity = filter.capacity); //把2当1
              console.log("capacity:", filter.capacity);
              let condition1 = item.capacity >= filter.capacity; //判断CAPACITY是否符合要求
              let isBusy;
              // 有些client有busy_time的属性有些没有
              if (item.busy_time) {
                if (item.busy_time.length > 0) {
                  isBusy = true;
                } else {
                  isBusy = false;
                }
              } else {
                if (item.busy) {
                  isBusy = true;
                } else {
                  isBusy = false;
                }
              }
              // console.log("isBusy:",isBusy);
              // if (!isBusy && condition1 && item.active === true) {
              if (condition1 && item.active === true) {
                // console.log("featureInstances:", item.featureInstances);
                let condition2; //判断AMENITIES是否符合要求
                if (item.featureInstances !== undefined) {
                  condition2 = item.featureInstances.length >= filter.amenities.length;
                } else {
                  condition2 = false;
                }
                // console.log('condition2:', condition2);
                if (filter.amenities.length > 0) {
                  //判断amenities是否符合要求
                  if (condition2) {
                    let newList = filter.amenities.filter(val => {
                      return item.featureInstances.indexOf(val) > -1;
                    });
                    if (newList.length == filter.amenities.length) {
                      arr.push(item);
                      if (item.busy_time) {
                        if (item.busy_time.length == 0) {
                          searchListArr.push(item);
                        } else {
                          let hasIntersection = false;
                          hasIntersection = this.timeIntersection(
                            filter.start,
                            filter.end,
                            item.busy_time[0].start,
                            item.busy_time[0].end
                          );
                          if (!hasIntersection) {
                            searchListArr.push(item);
                          }
                        }
                      } else {
                        if (item.busy == false) {
                          searchListArr.push(item);
                        }
                      }
                    }
                  }
                } else {
                  //amenities没有做条件筛选
                  // arr.push(item);
                  // if (item.busy_time.length == 0) {
                  //   searchListArr.push(item)
                  // }
                  arr.push(item);
                  if (item.busy_time) {
                    if (item.busy_time.length == 0) {
                      searchListArr.push(item);
                    } else {
                      let hasIntersection = false;
                      hasIntersection = this.timeIntersection(
                        filter.start,
                        filter.end,
                        item.busy_time[0].start,
                        item.busy_time[0].end
                      );
                      if (!hasIntersection) {
                        searchListArr.push(item);
                      }
                    }
                  } else {
                    if (item.busy == false) {
                      searchListArr.push(item);
                    }
                  }
                }
              }
            });

            // 把floorDataActive中的desk,给一个属性告知是有颜色状态的
            this.floorsDataActiveAndHasState = [];
            this.floorsDataActive.map(item => {
              let lengthRoom = this.roomsStateData.length;
              for (let i = 0; i < lengthRoom; i++) {
                let jtem = this.roomsStateData[i];
                if (item.resourceId == jtem.resourceId || item.resourceId == jtem.resourceEmail) {
                  this.floorsDataActiveAndHasState.push(item);
                }
              }
            });
            console.log(
              "searchListDesk--floorsDataActiveAndHasState",
              this.floorsDataActiveAndHasState
            );

            console.log("listData!!!!:", arr);
            console.log("searchListArr!!!!:", searchListArr);
            // this.listData = arr;
            this.listData = searchListArr;
            this.drawRoom(arr);
            this.bus.$emit("loading", false);
          })
          .catch(err => {
            console.log("走222");
            this.bus.$emit("loading", false);
          });
      } else {
        console.log("====11111111111111 =====");
        let startUnix;
        let endUnix;
        if (timezone) {
          startUnix = moment(filter.start, "X")
            .tz(timezone)
            .unix();
          endUnix = moment(filter.end, "X")
            .tz(timezone)
            .unix();
        } else {
          startUnix = filter.start;
          endUnix = filter.end;
        }
        this.getSeatsAvaiableReturn(startUnix, endUnix)
          .then(res => {
            this.bus.$emit("loading", false);
            if (res == null) {
              this.listData = [];
              return;
            }
            console.log("desksStateData:", res.data);
            console.log("deskData:", this.deskData);
            // this.desksStateData = res.data;
            this.desksStateData = res.data.data;
            console.log("desksStateData:", this.desksStateData);
            console.log("desksStateDataLength:", this.desksStateData.length);
            // 把floorDataActive中的desk,给一个属性告知是有颜色状态的
            this.floorsDataActiveAndHasState = [];
            this.floorsDataActive.map(item => {
              let length = this.desksStateData.length;
              for (let i = 0; i < length; i++) {
                let jtem = this.desksStateData[i];
                if (item.resourceId == jtem.resourceId || item.resourceId == jtem.resourceEmail) {
                  this.floorsDataActiveAndHasState.push(item);
                }
              }
            });
            this.deskData.map(item => {
              // 把desksStateData中的busy属性
              let length = this.desksStateData.length;
              console.log("length:", length);
              for (let i = 0; i < length; i++) {
                if (item.resourceId == this.desksStateData[i].resourceId) {
                  item.busy = this.desksStateData[i].busy;
                  // if (!item.busy && item.active) {
                  if (item.active) {
                    // console.log("====itemactive =====", item)
                    let condition2; //判断AMENITIES是否符合要求
                    if (item.featureInstances !== undefined) {
                      condition2 = item.featureInstances.length >= filter.amenities.length;
                    } else {
                      condition2 = false;
                    }
                    if (filter.amenities.length > 0) {
                      //判断amenities是否符合要求
                      if (condition2) {
                        let newList = filter.amenities.filter(val => {
                          return item.featureInstances.indexOf(val) > -1;
                        });
                        // 右侧显示不busy的房间
                        if (newList.length == filter.amenities.length) {
                          arr.push(item);
                          // 有些client有busy_time的属性有些没有
                          if (item.busy_time) {
                            if (item.busy_time.length == 0) {
                              searchListArr.push(item);
                            } else {
                              let hasIntersection = false;
                              hasIntersection = this.timeIntersection(
                                filter.start,
                                filter.end,
                                item.busy_time[0].start,
                                item.busy_time[0].end
                              );
                              if (!hasIntersection) {
                                searchListArr.push(item);
                              }
                            }
                          } else {
                            // 没有busy_time属性的用busy来判断
                            if (item.busy == false) {
                              searchListArr.push(item);
                            }
                          }
                        }
                      }
                    } else {
                      //amenities没有做条件筛选
                      arr.push(item);
                      if (item.busy_time) {
                        if (item.busy_time.length == 0) {
                          searchListArr.push(item);
                        } else {
                          let hasIntersection = false;
                          hasIntersection = this.timeIntersection(
                            filter.start,
                            filter.end,
                            item.busy_time[0].start,
                            item.busy_time[0].end
                          );
                          if (!hasIntersection) {
                            searchListArr.push(item);
                          }
                        }
                      } else {
                        if (item.busy == false) {
                          searchListArr.push(item);
                        }
                      }
                    }
                  }
                  break;
                }
              }
            });
            console.log("deskFilter:", arr);
            this.listData = searchListArr;
            console.log("searchListArr:", searchListArr);
            // this.listData = arr;
            this.drawDesk(arr);
            this.bus.$emit("loading", false);
          })
          .catch(err => {
            this.bus.$emit("loading", false);
          });
      }
    },
    showDateTime() {
      console.log("showDateTime");
      // this.showDate = !this.showDate;
      console.log("this.startTimeDialog:", this.startTimeDialog);
      this.showDate = true;
      this.dialogDate = true;
      setTimeout(() => {
        this.toBottom();
      }, 100);
      if (window.gtag) {
        window.gtag("event", "Space", {
          event_category: "Space custom timeframe click",
          event_label: "custom_timeframe",
          send_to: localStorage.getItem("googleAnaId") || "",
          dimension1: localStorage.getItem("campusName") || ""
        });
      }
    },
    // removeBluePath() {
    //   var objects = canvas.getObjects('path');
    //   for (let i in objects) {
    //
    //     if (objects[i].title === 'blue') {
    //       canvas.remove(objects[i]);
    //     }
    //
    //   }
    // },
    // getPath(item) {
    //
    //   let that = this;
    //   this.removeBluePath();
    //   canvas.renderAll();
    //   let pl = item.pointArrXY.length;
    //   if (pl > 3) {
    //     let color = '#4164FD';
    //
    //     let path = '';
    //     item.pointArrXY.forEach((j, l) => {
    //       if (l === 0) {
    //         path += 'M ' + j.x * num + ' ' + j.y * num;
    //       } else {
    //         if (pl === l + 1) {
    //           path += ' L ' + j.x * num + ' ' + j.y * num + ' z';
    //         } else {
    //           path += ' L ' + j.x * num + ' ' + j.y * num;
    //         }
    //       }
    //     });
    //     var dbx = new fabric.Path(path).set({
    //       id: item.id + 'blue',
    //       name: item.name,
    //       title: 'blue',
    //       top: item.pointArrXY[0].y * num + that.canvasH / 2 - (that.imageH * num / 2), //距离画布上边的距离
    //       left: item.pointArrXY[0].x * num + that.canvasW / 2 - (that.imageW * num / 2), //距离画布左侧的距离，单位是像素
    //       fill: color, //填充的颜色
    //       opacity: 0.5,
    //       stroke: 'orange', // 边框颜色
    //       hasControls: false,
    //       strokeWidth: 0, // 边框大小
    //       lockMovementX: true,
    //       lockMovementY: true
    //       // originX: 'center',
    //       // originY: 'center'
    //     });
    //     canvas.add(dbx);
    //
    //     canvas.renderAll();
    //   }
    // },

    removeBluePath() {
      var objects = canvas.getObjects("polygon");
      for (let i in objects) {
        if (objects[i].title === "blue") {
          canvas.remove(objects[i]);
        }
      }
    },
    getPath(item) {
      // console.log("getPath!!!!!!!!!!!:", item);
      let that = this;
      this.removeBluePath();

      // var objects = canvas.getObjects('path');
      // for (let i in objects) {
      //
      //   if (objects[i].title === 'blue') {
      //     canvas.remove(objects[i]);
      //   }
      //
      // }
      canvas.renderAll();
      item.pointArrXY = [];
      for (let i = 0; i < item.points.length; i++) {
        let newI = item.points[i].split(",");
        let obj = {};
        if (!isNaN(newI[0]) && !isNaN(newI[1])) {
          obj.x = newI[0];
          obj.y = newI[1];
          item.pointArrXY.push(obj);
        }
      }
      item.pointArrXY.map(item => {
        item.x = item.x * num + Number(that.canvasW / 2) - Number((that.imageW * num) / 2);
        item.y = item.y * num + Number(that.canvasH / 2) - Number((that.imageH * num) / 2);
      });
      let pl = item.pointArrXY.length;
      // console.log("item.pointArrXY:", item.pointArrXY)
      if (pl > 3) {
        let color = "#4164FD";
        if (this.client == "NatWest") {
          color = "#FB7182";
        }
        // item.pointArrXY.map(item => {
        //   item.x = item.x * num + Number(that.canvasW / 2) - Number(that.imageW * num / 2);
        //   item.y = item.y * num + Number(that.canvasH / 2) - Number(that.imageH * num / 2);
        // })
        let polygon = new fabric.Polygon(item.pointArrXY, {
          id: item.id + "blue",
          name: item.name,
          title: "blue",
          fill: color,
          hasControls: false,
          opacity: 0.5,
          strokeWidth: 0, // 边框大小
          lockMovementX: true,
          lockMovementY: true
        });
        canvas.add(polygon);

        canvas.renderAll();
      }
    },
    async acceptMap(data) {
      this.showLocation(data);
      // this.getPath(data);
    },
    // search List筛选出的floor重新渲染地图和列表
    refreshFilter(data) {
      console.log("refreshFilter:", data);
      this.showList = false;
      this.floorChange2(data, data.floor);
      this.showList = true;
    },
    clearObjBlue() {
      var objects = canvas.getObjects("polygon");
      for (let i in objects) {
        if (objects[i].title === "blue") {
          canvas.remove(objects[i]);
        }
      }
      canvas.renderAll();
    },
    // search 和 search List的界面切换
    changeShowList(data) {
      this.showList = data;
      if (data) {
        this.showDate = false;
      } else {
        this.showDate = true;
      }
      this.clearObjBlue();
    },
    clickCross() {
      this.clearObjBlue();
    },
    // 清除所有desk和room
    clearPath() {
      var objects = canvas.getObjects("polygon");
      for (let i in objects) {
        canvas.remove(objects[i]);
      }
      canvas.renderAll();
    },
    clearTargetpath(id) {
      var objects = canvas.getObjects("polygon");
      for (let i in objects) {
        if (id == objects[i].id) {
          canvas.remove(objects[i]);
          break;
        }
      }
      canvas.renderAll();
    },
    // clearPath() {
    //   var objects = canvas.getObjects('path');
    //   for (let i in objects) {
    //     canvas.remove(objects[i]);
    //   }
    //   canvas.renderAll();
    // },

    sensorStatus(sensorIds) {
      if (!showSensor) {
        return;
      }
      this.$axios({
        method: "post",
        url: "/m/spaces/sensorStatus",
        data: {
          floorId: this.selectFloorId,
          meetingId: localStorage.getItem("meetingIdMap"),
          sensorIds: this.sensorIds
        }
      })
        .then(res => {
          this.drawSensors(res.data.data);
        })
        .catch(err => {
          console.log(err);
        });
    },
    sensorStatusEasy() {
      if (!showSensor) {
        return;
      }
      let arr = [];
      this.roomData.map(item => {
        arr.push(item.sensorId);
      });
      this.sensorStatus(arr);
    },
    async refreshAll() {
      console.log("执行refreshAll！！！！！！！！");
      this.showSearch = true;
      this.showList = false;
      let that = this;
      this.clearPath();
      this.bus.$emit("loading", true);
      let timezone = localStorage.getItem("timezone");
      let startUnix;
      let endUnix;
      let endTimePlusTwoUnix;
      let startTimePlusTwoUnix;
      let startTimePlusNineUnix;
      if (timezone) {
        startUnix = moment.tz(this.startTime, "MMM D, h:mm a", timezone).unix();
        endUnix = moment.tz(this.endTime, "MMM D, h:mm a", timezone).unix();
        endTimePlusTwoUnix = moment
          .tz(this.endTime, "MMM D, h:mm a", timezone)
          .add(2, "hours")
          .unix();
        startTimePlusTwoUnix = moment
          .tz(this.startTime, "MMM D, h:mm a", timezone)
          .add(2, "hours")
          .unix();
        startTimePlusNineUnix = moment
          .tz(this.startTime, "MMM D, h:mm a", timezone)
          .add(9, "hours")
          .add(-1, "seconds")
          .unix();
      } else {
        startUnix = moment(this.startTime, "MMM D, h:mm a").unix();
        endUnix = moment(this.endTime, "MMM D, h:mm a").unix();
        endTimePlusTwoUnix = moment(this.endTime, "MMM D, h:mm a")
          .add(2, "hours")
          .unix();
        startTimePlusTwoUnix = moment(this.startTime, "MMM D, h:mm a")
          .add(2, "hours")
          .unix();
      }
      // let startUnix = moment(this.startTime, "MMM D, h:mm a").unix();
      // let endUnix = moment(this.endTime, "MMM D, h:mm a").unix();
      // let endTimePlusTwoUnix = moment(this.endTime, "MMM D, h:mm a").add(2, 'hours').unix();
      // let startTimePlusTwoUnix = moment(this.startTime, "MMM D, h:mm a").add(2, 'hours').unix();
      let d = new Date();
      d.setSeconds(0);
      // let endTimeToEnd = moment(d).endOf('day').unix();
      let deskEndTime;
      if (this.client == "Applied Materials") {
        deskEndTime = startTimePlusTwoUnix;
      } else if (this.client == "Edwards Life Sciences") {
        // let startTimePlusNineUnix = moment(this.endTime, "MMM D, h:mm a").add(9, 'hours').unix();
        deskEndTime = startTimePlusNineUnix;
      } else {
        let timezone = localStorage.getItem("timezone");
        if (timezone) {
          deskEndTime = moment(d)
            .tz(timezone)
            .endOf("day")
            .unix();
        } else {
          deskEndTime = moment(d)
            .endOf("day")
            .unix();
        }
      }
      console.log("deskEndTime~~~", deskEndTime);

      let fun1 = function() {
        return new Promise((resolve, reject) => {
          /* 你的逻辑代码 */
          let freeBusy = that.freeBusyReturn(startUnix, endTimePlusTwoUnix);
          // let freeBusy = that.freeBusyReturn(startUnix, endUnix);
          resolve(freeBusy);
        });
      };
      let fun2 = function() {
        return new Promise((resolve, reject) => {
          /* 你的逻辑代码 */
          // let seatAvail = that.getSeatsAvaiableReturn(startUnix, endTimeToEnd)
          console.log("startUnix1111111111", startUnix);
          console.log("deskEndTime111111111111", deskEndTime);
          let seatAvail = that.getSeatsAvaiableReturn(startUnix, deskEndTime);

          console.log("==== seatAvail!!!!!!=====", seatAvail);
          resolve(seatAvail);
        });
      };
      console.log("fun1", fun1);
      console.log("fun2", fun2);
      let run = function() {
        Promise.allSettled([fun1(), fun2()])
          .then(res => {
            if (res[0].status === "fulfilled" && res[0].value) {
              that.roomsStateData = res[0].value.data.data;
            } else {
              that.roomsStateData = [];
            }
            // console.log('====',that.floorsDataActive);
            // that.floorsDataActive.map(item=>{
            //   let length = that.roomsStateData.length;
            //   item.noMatch = true;
            //   for(let i = 0; i < length; i++){
            //     let jtem = that.roomsStateData[i];
            //     if(item.resourceId == jtem.resourceId ||item.resourceId == jtem.resourceEmail){
            //       item.noMatch = false;
            //       break
            //     }
            //   }
            // })
            that.drawRoom();
            // console.log("====================floorsDataActive========:",that.floorsDataActive)
            console.log("res1111:", res[1]);
            if (res[1].status === "fulfilled" && res[1].value) {
              // that.desksStateData = res[1].value.data;
              that.desksStateData = res[1].value.data.data;
              // 把floorDataActive中的desk,给一个属性告知是有颜色状态的
              that.floorsDataActiveAndHasState = [];
              that.floorsDataActive.map(item => {
                let length = that.desksStateData.length;
                for (let i = 0; i < length; i++) {
                  let jtem = that.desksStateData[i];
                  if (item.resourceId == jtem.resourceId || item.resourceId == jtem.resourceEmail) {
                    that.floorsDataActiveAndHasState.push(item);
                    that.desksStateData[i].points = item.points; //给deskState加个points属性，因为左上角的available统计的前提是points不为空
                  }
                }

                let lengthRoom = that.roomsStateData.length;
                for (let i = 0; i < lengthRoom; i++) {
                  let jtem = that.roomsStateData[i];
                  if (item.resourceId == jtem.resourceId || item.resourceId == jtem.resourceEmail) {
                    that.floorsDataActiveAndHasState.push(item);
                  }
                }
              });
              console.log(
                "floorsDataActiveAndHasState!!!!!!!!!!!",
                that.floorsDataActiveAndHasState
              );
              console.log("desksStateData:", that.desksStateData);
              let arr = [];
              let length = that.deskData.length;
              let deskDataHavePoints = []; ////用来当Floor capacity的分母
              let length2 = that.desksStateData.length;
              console.log("length2:", length2);
              for (let i = 0; i < length; i++) {
                let item = that.deskData[i];
                if (item.points.length > 0 && item.resourceId) {
                  deskDataHavePoints.push(item);
                }
                for (let j = 0; j < length2; j++) {
                  let jtem = that.desksStateData[j];
                  if (
                    item.resourceId == jtem.resourceId &&
                    jtem.active == true &&
                    item.points.length > 0
                  ) {
                    arr.push(jtem);
                    break;
                  }
                }
              }

              that.drawDesk();
              let green = 0;
              let red = 0;
              let active = 0;
              let inActive = 0;

              that.desksStateData.map(item => {
                if (item.active) {
                  active += 1;
                  if (item.busy) {
                    red += 1;
                  } else {
                    if (item.points.length > 0) {
                      green += 1;
                    }
                  }
                } else {
                  inActive += 1;
                }
              });

              // let point = (green + red) / (green + red + inActive);
              // let point = arr.length / that.deskData.length;
              let point = arr.length / deskDataHavePoints.length;
              console.log("arr！！！！！！！！", arr);
              console.log("deskData.length！！！！！！！！", that.deskData.length);
              console.log("point！！！！！！！！", point);

              if (isNaN(point)) {
                that.floorCapacity = "0%";
              } else {
                if (point == 1) {
                  that.floorCapacity = "100%";
                } else {
                  that.floorCapacity = Number(point * 100).toFixed(1) + "%";
                }
              }

              that.availableDesk = green;
              that.desksBooked = red;
              // return true;
            } else if (res[1].status === "fulfilled" && res[1].value == undefined) {
              that.floorCapacity = "0%";
              that.availableDesk = 0;
              that.desksBooked = 0;
              that.drawDesk();
            }

            // return true;
            else if (res[1].status === "fulfilled" && res[1].value == undefined) {
              that.floorCapacity = "0%";
              that.availableDesk = 0;
              that.desksBooked = 0;
              that.drawDesk();
            }

            // 显示checkIn的pop up
            let checkInDeskId = localStorage.getItem("checkInDeskId");
            if (checkInDeskId) {
              that.bus.$emit("loading", true);
              that
                .getOneUserSeatReturn()
                .then(res => {
                  that.bus.$emit("loading", false);
                  console.log("getOneUserSeatReturn123", res);
                  let data = res.data.data;
                  console.log("data123:", data);
                  if (data.length <= 0) {
                    that
                      .$confirm({
                        title: "Confirmation",
                        message: "You have already checked in, or the check-in has expired.",
                        show: true
                      })
                      .then(res => {
                        localStorage.removeItem("checkInDeskId");
                      });
                    return;
                  }
                  let desk = res.data.data[0];
                  let option = desk.checkInReminderMinutes;
                  console.log("option:", option);
                  console.log("check-in-desk:", desk);
                  that.checkInDeskName = desk.seat_name;
                  let startTime;
                  let timezone = localStorage.getItem("timezone");
                  if (timezone) {
                    startTime = moment.tz(moment.utc(desk.start), timezone).format("hh:mm a");
                  } else {
                    startTime = moment.utc(desk.start).format("hh:mm a");
                  }
                  console.log("startTime:", startTime);
                  that.checkInDesk = desk;
                  // 有匹配上的desk，则先判断是否checkIn过
                  if (desk) {
                    if (desk.checkIn) {
                      that
                        .$confirm({
                          title: "Confirmation",
                          message: "You have already checked in, or the check-in has expired.",
                          show: true
                        })
                        .then(res => {
                          localStorage.removeItem("checkInDeskId");
                        });
                    } else {
                      let d = new Date();
                      d.setSeconds(0);
                      let campusTime = moment(moment.utc(desk.start))
                        .set({ second: 0 })
                        .unix();
                      let diff = Number(moment(d).diff(moment(campusTime, "X"), "minutes"));
                      console.log("now:", moment(d));
                      console.log("diff:", diff);
                      console.log("enableDeskAutoRelease:", option.enableDeskAutoRelease);
                      // 分三种情况讨论
                      if (!option.enableDeskAutoRelease) {
                        console.log("走1");
                        if (diff < option.deskCheckInFirstReminderMinutes) {
                          console.log("1");
                          that
                            .$confirm({
                              title: "Confirmation",
                              message: "Cannot checkin this time.",
                              show: true
                            })
                            .then(res => {
                              localStorage.removeItem("checkInDeskId");
                            });
                        } else if (
                          diff >= option.deskCheckInFirstReminderMinutes &&
                          diff < option.deskCheckInSecondReminderMinutes
                        ) {
                          console.log("2");

                          that.showCheckIn = true;
                          that.checkInMsg = `You have a workspace reservation for ${desk.seat_name} starting at ${startTime}`;
                        } else if (
                          diff >= option.deskCheckInSecondReminderMinutes &&
                          diff < option.deskCheckInThirdReminderMinutes
                        ) {
                          console.log("3");

                          that.showCheckIn = true;
                          that.checkInMsg = `Your workspace reservation for ${desk.seat_name} has started. Please check in to confirm your reservation.`;
                        } else if (
                          diff >= option.deskCheckInThirdReminderMinutes &&
                          diff < option.deskReservationAutoReleaseMinutes
                        ) {
                          console.log("4");
                          that.showCheckIn = true;
                          that.checkInMsg = `Your workspace reservation for ${desk.seat_name} has started. Please check in NOW to confirm your reservation.`;
                        } else {
                          console.log("5");
                          that.showCheckIn = true;
                          that.checkInMsg = `Your workspace reservation for ${desk.seat_name} has started. Please check in NOW to confirm your reservation.`;
                        }
                      } else {
                        console.log("走2!!");
                        console.log("diff:", diff);
                        console.log(
                          "option.deskCheckInFirstReminderMinutes:",
                          option.deskCheckInFirstReminderMinutes
                        );
                        if (diff < option.deskCheckInFirstReminderMinutes) {
                          console.log("type 1");
                          that
                            .$confirm({
                              title: "Confirmation",
                              message: "Cannot checkin this time.",
                              show: true
                            })
                            .then(res => {
                              localStorage.removeItem("checkInDeskId");
                            });
                        } else if (
                          diff >= option.deskCheckInFirstReminderMinutes &&
                          diff < option.deskCheckInSecondReminderMinutes
                        ) {
                          console.log("type 2");
                          that.showCheckIn = true;
                          that.checkInMsg = `You have a workspace reservation for ${desk.seat_name} starting at ${startTime}`;
                        } else if (
                          diff >= option.deskCheckInSecondReminderMinutes &&
                          diff < option.deskCheckInThirdReminderMinutes
                        ) {
                          console.log("type 3");
                          that.showCheckIn = true;
                          console.log(
                            "option.deskReservationAutoReleaseMinutes:",
                            option.deskReservationAutoReleaseMinutes
                          );
                          let withinMin = Number(option.deskReservationAutoReleaseMinutes) - diff;
                          console.log("withinMin:", withinMin);
                          that.checkInMsg = `Your workspace reservation for ${desk.seat_name} has started. Please check in within ${withinMin} minutes or your reservation will automatically be cancelled.`;
                        } else if (
                          diff >= option.deskCheckInThirdReminderMinutes &&
                          diff < option.deskReservationAutoReleaseMinutes
                        ) {
                          console.log("type 4");
                          that.showCheckIn = true;
                          let withinMin = Number(option.deskReservationAutoReleaseMinutes) - diff;
                          that.checkInMsg = `Your workspace reservation for ${desk.seat_name} has started. Please check in NOW or your reservation will automatically be cancelled in ${withinMin} minutes.`;
                        } else if (diff >= option.deskReservationAutoReleaseMinutes) {
                          console.log("type 5");
                          that
                            .$confirm({
                              title: "Confirmation",
                              message: "You have already checked in, or the check-in has expired.",
                              show: true
                            })
                            .then(res => {
                              localStorage.removeItem("checkInDeskId");
                            });
                        }
                      }
                      // if (diff <= 15) {
                      //   that.checkInDeskName = desk.seat_name;
                      //   that.checkInMsg = `You have a desk reservation starting NOW at ${desk.seat_name}.`;
                      //   that.showCheckIn = true;
                      // } else {
                      //   that
                      //     .$confirm({
                      //       title: "Confirmation",
                      //       message: "You have already checked in, or the check-in has expired.",
                      //       show: true
                      //     })
                      //     .then(res => {
                      //       localStorage.removeItem("checkInDeskId");
                      //     });
                      // }
                    }
                  } else {
                    // 没找到匹配的desk，直接给提示
                    that
                      .$confirm({
                        title: "Confirmation",
                        message: "You have already checked in, or the check-in has expired.",
                        show: true
                      })
                      .then(res => {
                        localStorage.removeItem("checkInDeskId");
                      });
                  }
                })
                .catch(err => {
                  that.bus.$emit("loading", false);
                });
            }

            // map 监听键盘 上和下键选择room或desk
            window.addEventListener("keydown", that.MapBlur, true);
            that.bus.$emit("loading", false);
            //开始记录用户未操作时间
            that.stopInterval();
            that.startInterval();
          })
          .catch(err => {
            that.bus.$emit("loading", false);
            console.log(err);
          });
      };
      run();
    },
    backToSearchList(time) {
      this.bus.$emit("loading", true);
      console.log("backToSearchList-time:", time);
      this.showTodayBtn = false;
      this.showList = false;
      // this.showDeskDetail = false;
      this.showSearch = true;
      this.clearObjBlue();
      this.refreshCurrentDate();
      this.getDefaultMap();
      this.listData = [];
    },
    // backToSearchList(time) {
    //   console.log('backToSearchList-time:', time);
    //   this.showList = false;
    //   // this.showDeskDetail = false;
    //   this.showSearch = true;
    //   this.clearObjBlue();
    //   if (time.start !== '') {
    //     // 暂时弃用，原本是右侧点击右上角关闭按钮space显示search的时间，现在统一都显示默认时间了
    //     this.startTime = moment(time.start, "X").format("MMM D, h:mm a");
    //     this.endTime = moment(time.end, "X").format("MMM D, h:mm a");
    //   } else {
    //     this.refreshCurrentDate();
    //     this.getDefaultMap();
    //   }
    //   // this.refreshAll();
    // },
    // 点击日历
    async clickCanlender(data) {
      console.log("clickCanlender:", data);
      this.showDeskInfo = false;
      this.getDeskItem(data.id, true);
      //deskbooking底部按钮显示可以预定的时间
      this.bookingTime = {};
      this.bookingTime.start = data.start;
      this.bookingTime.end = data.end;
      this.bookingTime.timeType = data.timeType;
      this.showDeskDetail = true;
    },
    handleResize() {
      this.$nextTick(() => {
        this.getBuildingsAndFloorsData();
      });
    },
    getBuildingsAndFloorsData() {
      this.bus.$emit("loading", true);
      let that = this;
      let meetingId = localStorage.getItem("meetingIdMap");
      this.$axios({
        method: "get",
        url:
          this.client == "HPE" || this.client == "HPE Campus"
            ? "m/v2/spaces/maps"
            : "m/spaces/maps",
        data: {
          meetingId: localStorage.getItem("meetingIdMap")
        }
      })
        .then(res => {
          if (res.data.result === "succeeded") {
            // that.buildingsAndFloorsData = res.data;
            that.buildingsAndFloorsData = res.data.data;
            let length = that.buildingsAndFloorsData.length;
            for (let i = 0; i < length; i++) {
              let item = that.buildingsAndFloorsData[i];
              if (item.floors.length > 0) {
                that.noFloor = false;
                break;
              }
            }
            this.getUserLocation();
          } else {
            this.bus.$emit("loading", false);
          }
        })
        .catch(err => {
          this.bus.$emit("loading", false);
          console.log(err);
        });
    },
    async getAllData(floor) {
      let that = this;
      this.$axios({
        method: "get",
        url:
          this.client == "HPE" || this.client == "HPE Campus" ? "m/v2/spaces/map" : "m/spaces/map",
        data: {
          pageName: "map",
          mapId: floor.id,
          meetingId: localStorage.getItem("meetingIdMap")
        }
      })
        .then(res => {
          if (res.data.result === 200) {
            // 获取room
            that.roomResourceIdString = "";
            let lastIndex = res.data.data.resources.length - 1;
            res.data.data.resources.map((item, index) => {
              if (item.active && item.type == "room" && item.resourceId != "" && item.points) {
                that.roomResourceIdString += item.resourceId + ",";
              }
            });
            that.roomResourceIdString = that.roomResourceIdString.substring(
              0,
              that.roomResourceIdString.length - 1
            );
            // console.log('roomResourceIdString:', that.roomResourceIdString);

            // desk
            console.log("floorsData", res);
            that.floorsDataActive = [];
            that.floorsData = res.data.data.resources;
            that.roomData = [];
            that.deskData = [];
            that.floorsData.map(item => {
              if (item.type == "room") {
                that.roomData.push(item); //room的数据
              } else {
                that.deskData.push(item); //desk的数据
              }
              if (item.active == true) {
                that.floorsDataActive.push(item);
              }
            });
            console.log("floorsDataActive", that.floorsDataActive);
            console.log("roomData22", that.roomData);
            console.log("deskData", that.deskData);
            // 当前sensor功能只开放给warnermedia
            if (that.client == "WarnerMedia") {
              that.sensorIds = [];
              that.roomData.map(item => {
                if (item.sensorId) {
                  that.sensorIds.push(item.sensorId);
                }
              });
              console.log("that.sensorIds````````````", that.sensorIds);
              that.sensorStatus(that.sensorIds);
            }

            // resolve(res);
            // var url = 'https://cx-app-edwards-stage.uc.r.appspot.com/' + floor.url;
            var url = floor.url;
            return new Promise((resolve, reject) => {
              let img = new Image();
              img.onload = () => resolve(img);
              img.onerror = reject;
              img.src = url;
            });
          } else {
            that.bus.$emit("loading", false);
          }
        })
        .then(res => {
          let that = this;
          this.imageW = res.width;
          this.imageH = res.height;
          // console.log('image.width:',this.imageW); // 打印图片信息
          // console.log('image.height:',this.imageH); // 打印图片信息
          this.canvasW = canvas.width;
          this.canvasH = canvas.height;
          // 刷新所有desk和room的状态
          this.refreshAll();
        })
        .catch(err => {
          that.bus.$emit("loading", false);
          console.log(err);
        });
    },
    async getAllData2(floor) {
      let that = this;
      this.$axios({
        method: "get",
        url:
          this.client == "HPE" || this.client == "HPE Campus" ? "m/v2/spaces/map" : "m/spaces/map",
        data: {
          pageName: "map",
          mapId: floor.id,
          meetingId: localStorage.getItem("meetingIdMap")
        }
      })
        .then(res => {
          console.log("resresresresresresresres", res);
          if (res.data.result === 200) {
            // 获取room
            that.roomResourceIdString = "";
            res.data.data.resources.map(item => {
              if (item.type == "room") {
                that.roomResourceIdString += item.resourceId + ",";
              }
            });
            // console.log('roomResourceIdString:', that.roomResourceIdString);

            // desk
            console.log("floorsData", res);
            that.floorsData = res.data.data.resources;
            that.roomData = [];
            that.deskData = [];
            that.floorsData.map(item => {
              console.log("item.type", item.type);
              if (item.type == "room") {
                that.roomData.push(item); //room的数据
              } else {
                that.deskData.push(item); //desk的数据
              }
            });
            console.log("roomData", that.roomData);
            console.log("deskData", that.deskData);

            // resolve(res);
            // var url = 'https://cx-app-edwards-stage.uc.r.appspot.com/' + floor.url;
            var url = floor.url;
            return new Promise((resolve, reject) => {
              let img = new Image();
              img.onload = () => resolve(img);
              img.onerror = reject;
              img.src = url;
            });
          } else {
            that.bus.$emit("loading", false);
          }
        })
        .then(res => {
          let that = this;
          this.imageW = res.width;
          this.imageH = res.height;
          // console.log('image.width:',this.imageW); // 打印图片信息
          // console.log('image.height:',this.imageH); // 打印图片信息
          this.canvasW = canvas.width;
          this.canvasH = canvas.height;
          // 刷新所有desk和room的状态
          // this.refreshAll();
        })
        .then(res => {
          this.changeSearchList(this.currentSearchFilter);
        })
        .catch(err => {
          that.bus.$emit("loading", false);
          console.log(err);
        });
    },

    // async getAllData(floor) {
    //   let url = "";
    //   let that = this;
    //   url = "m/spaces/map?pageName=map&";
    //   if (floor.id) {
    //     url += `mapId=${floor.id}&`;
    //   }
    //   if (localStorage.meetingIdMap) {
    //     url += `meetingId=${localStorage.meetingIdMap}&`;
    //   }
    //   // if (localStorage.token) {
    //   //   url += `token=${localStorage.token}&`;
    //   // }
    //   this.get(url, {})
    //     .then((res) => {
    //       // console.log("res!!", res.data);
    //       if (res.result === 200) {
    //         // 获取room
    //         that.roomResourceIdString = "";
    //         let lastIndex = res.data.resources.length - 1;
    //         res.data.resources.map((item, index) => {
    //           if (item.active && item.type == 'room' && item.resourceId != '' && item.points) {
    //             // console.log(item, index)
    //             that.roomResourceIdString += item.resourceId + ',';
    //           }
    //         })
    //         that.roomResourceIdString = that.roomResourceIdString.substring(0, that.roomResourceIdString.length - 1)
    //         // console.log('roomResourceIdString:', that.roomResourceIdString);
    //
    //         // desk
    //         console.log('floorsData', res);
    //         that.floorsDataActive = [];
    //         that.floorsData = res.data.resources;
    //         that.roomData = [];
    //         that.deskData = [];
    //         that.floorsData.map(item => {
    //           if (item.type == 'room') {
    //             that.roomData.push(item);//room的数据
    //           } else {
    //             that.deskData.push(item);//desk的数据
    //           }
    //           if (item.active == true) {
    //             that.floorsDataActive.push(item);
    //           }
    //         })
    //         console.log('floorsDataActive', that.floorsDataActive);
    //         console.log('roomData22', that.roomData);
    //         console.log('deskData', that.deskData);
    //         // 当前sensor功能只开放给warnermedia
    //         if (that.client == 'WarnerMedia') {
    //           that.sensorIds = [];
    //           that.roomData.map(item => {
    //             if (item.sensorId) {
    //               that.sensorIds.push(item.sensorId);
    //             }
    //           })
    //           that.sensorStatus(that.sensorIds);
    //         }
    //
    //
    //         // resolve(res);
    //         // var url = 'https://cx-app-edwards-stage.uc.r.appspot.com/' + floor.url;
    //         var url = floor.url;
    //         return new Promise((resolve, reject) => {
    //           let img = new Image();
    //           img.onload = () => resolve(img);
    //           img.onerror = reject;
    //           img.src = url;
    //         })
    //       } else {
    //         that.bus.$emit('loading', false);
    //         reject(error);
    //       }
    //     })
    //     .then((res) => {
    //       let that = this;
    //       this.imageW = res.width;
    //       this.imageH = res.height;
    //       // console.log('image.width:',this.imageW); // 打印图片信息
    //       // console.log('image.height:',this.imageH); // 打印图片信息
    //       this.canvasW = canvas.width;
    //       this.canvasH = canvas.height;
    //       // 刷新所有desk和room的状态
    //       this.refreshAll();
    //     }).catch((err) => {
    //     that.bus.$emit('loading', false);
    //     console.log(err);
    //   })
    // },
    // async getAllData2(floor) {
    //   let url = "";
    //   let that = this;
    //   url = "m/spaces/map?pageName=map&";
    //   if (floor.id) {
    //     url += `mapId=${floor.id}&`;
    //   }
    //   if (localStorage.meetingIdMap) {
    //     url += `meetingId=${localStorage.meetingIdMap}&`;
    //   }
    //   // if (localStorage.token) {
    //   //   url += `token=${localStorage.token}&`;
    //   // }
    //   this.get(url, {})
    //     .then((res) => {
    //       if (res.result === 200) {
    //         // 获取room
    //         that.roomResourceIdString = "";
    //         res.data.resources.map(item => {
    //           if (item.type == 'room') {
    //             that.roomResourceIdString += item.resourceId + ',';
    //           }
    //         })
    //         // console.log('roomResourceIdString:', that.roomResourceIdString);
    //
    //         // desk
    //         console.log('floorsData', res);
    //         that.floorsData = res.data.resources;
    //         that.roomData = [];
    //         that.deskData = [];
    //         that.floorsData.map(item => {
    //           if (item.type == 'room') {
    //             that.roomData.push(item);//room的数据
    //           } else {
    //             that.deskData.push(item);//desk的数据
    //           }
    //         })
    //         console.log('roomData', that.roomData);
    //         console.log('deskData', that.deskData);
    //
    //         // resolve(res);
    //         // var url = 'https://cx-app-edwards-stage.uc.r.appspot.com/' + floor.url;
    //         var url = floor.url;
    //         return new Promise((resolve, reject) => {
    //           let img = new Image();
    //           img.onload = () => resolve(img);
    //           img.onerror = reject;
    //           img.src = url;
    //         })
    //       } else {
    //         that.bus.$emit('loading', false);
    //         reject(error);
    //       }
    //     })
    //     .then((res) => {
    //       let that = this;
    //       this.imageW = res.width;
    //       this.imageH = res.height;
    //       // console.log('image.width:',this.imageW); // 打印图片信息
    //       // console.log('image.height:',this.imageH); // 打印图片信息
    //       this.canvasW = canvas.width;
    //       this.canvasH = canvas.height;
    //       // 刷新所有desk和room的状态
    //       // this.refreshAll();
    //     })
    //     .then(res => {
    //       this.changeSearchList(this.currentSearchFilter);
    //     })
    //     .catch((err) => {
    //       that.bus.$emit('loading', false);
    //       console.log(err);
    //     })
    // },
    campusChange() {
      let that = this;
      this.buildingsAndFloorsData.map(item => {
        // console.log(item);
        if (item.id === that.campus.id) {
          console.log("floorData:", floorData);
          that.floorData = item.floors;
        }
      });
    },
    floorChange(building, floor) {
      // console.log("building:",building)
      console.log("floor:", floor);
      this.keydownIndex = 0;
      let floorName = floor.name ? floor.name : floor.label;
      this.current = building.buildingName + ", " + floorName;
      this.bfData = {
        buildingName: building.buildingName,
        floorName: floorName,
        currentFloor: floorName
      };
      this.selectBuildingId = building.id;
      this.selectFloorId = floor.id;
      this.showSearch = true;
      this.floor = floor;
      this.selectFloor = false;
      this.initCanvas(floor);
      this.getAllData(floor);
    },
    async floorChange2(building, floor) {
      console.log("=======floorChange2========");
      console.log("building:", building);
      console.log("floor:", floor);
      this.keydownIndex = 0;
      let floorName = floor.name ? floor.name : floor.label;
      this.current = building.buildingName + ", " + floorName;
      this.selectBuildingId = building.id;
      this.selectFloorId = floor.id;
      this.showSearch = true;
      this.floor = floor;
      this.selectFloor = false;
      this.initCanvas(floor);
      this.getAllData2(floor);
    },
    // 选择楼层
    select() {
      this.selectFloor = true;
      if (window.gtag) {
        window.gtag("event", "Space", {
          event_category: "Select floor click",
          event_label: "select floor",
          send_to: localStorage.getItem("googleAnaId") || "",
          dimension1: localStorage.getItem("campusName") || ""
        });
      }

      console.log(this.buildingsAndFloorsData);
    },
    initCanvas(item) {
      let startTime, endTime;
      let that = this;
      if (canvas) {
        canvas.clear();
        canvas.dispose();
      }
      // 1. 实例化canvas 画布
      canvas = new fabric.Canvas("floorMap");
      // 2. 设置背景图片作为底图（这里导入图片使用require，不要 使用 '../../' 方式）
      canvas.width = this.$refs.floorMapBlock.offsetWidth;
      // 170是上面campus name的高度加日期和圆点的高度
      let height = this.$refs.mapClass.offsetHeight - 170;
      canvas.height = height;
      canvas.setHeight(height);
      // canvas.height = this.$refs.mapClass.offsetHeight;
      // canvas.setHeight(this.$refs.mapClass.offsetHeight);

      canvas.setWidth(this.$refs.floorMapBlock.offsetWidth);
      var center = canvas.getCenter();
      canvas.controlsAboveOverlay = true;
      // var url = 'https://cx-app-edwards-stage.uc.r.appspot.com/' + item.url;
      var url = item.url;
      // const image = new window.Image();
      //
      // image.src = url;
      // image.onload = function () {
      //   // console.log(image);
      //   this.imageW = image.width;
      //   this.imageH = image.height;
      //   console.log('image.width:',this.imageW); // 打印图片信息
      //   console.log('image.height:',this.imageH); // 打印图片信息
      //   this.canvasW = canvas.width;
      //   this.canvasH = canvas.height;
      // };
      fabric.Image.fromURL(url, img => {
        img.set({
          opacity: 1,
          scaleX: 1,
          scaleY: 1,
          top: center.top,
          left: center.left,
          originX: "center",
          originY: "center"
        });
        img.scale(num);
        // console.log(canvas.width/img.width);
        // canvas.add(img).setActiveObject(img);
        canvas.setBackgroundImage(img, canvas.requestRenderAll.bind(canvas)).renderAll();
      });

      //鼠标按下事件
      //如果用户选择meeting的15m 30m 1HR 2HR 或则 desk的Today > end of Date则开始时间具体到当前分钟数（如hh:12），否则显示整点（如hh:00或hh:30）
      canvas.on("mouse:down", function(e) {
        this.panning = true;
        canvas.selection = false;
        // startTime = new Date().getTime();//获取事件开始时间

        let d = new Date();
        d.setSeconds(0);
        let startTime;
        let timezone = localStorage.getItem("timezone");
        if (timezone) {
          startTime = moment(d).valueOf(); //获取事件开始时间
        } else {
          startTime = new Date().getTime(); //获取事件开始时间
        }
        if (e.target) {
          that.floorsData.forEach((item, k) => {
            if (item.id === e.target.id) {
              that.currentItem = item;
              console.log("e.target:!!!!", e.target);
              // ROOM
              if (item.type == "room") {
                if (e.target.cannotClick) {
                  return;
                }
                let string = that.year + that.startTime;
                let string2 = that.year + that.endTime;
                let d = new Date();
                d.setSeconds(0);
                that.bookingTime = {};
                if (timezone) {
                  if (that.showTodayBtn) {
                    that.bookingTime.start = moment
                      .tz(string, "YYYYMMM D, h:mm a", timezone)
                      .unix(); //选择了custom
                  } else {
                    that.bookingTime.start = moment.tz(d, timezone).unix(); //选择了15m 30m 1HR 2HR
                  }
                  that.bookingTime.end = moment.tz(string2, "YYYYMMM D, h:mm a", timezone).unix();
                } else {
                  if (that.showTodayBtn) {
                    that.bookingTime.start = moment(string, "YYYYMMM D, h:mm a").unix();
                  } else {
                    that.bookingTime.start = moment(d, timezone).unix();
                  }
                  that.bookingTime.end = moment(string2, "YYYYMMM D, h:mm a").unix();
                }
                // if(timezone){
                //   that.bookingTime.start = moment.tz(string, "YYYYMMM D, h:mm a",timezone).unix();
                //   that.bookingTime.end = moment.tz(string2, "YYYYMMM D, h:mm a",timezone).unix();
                // }else{
                //   that.bookingTime.start = moment(string, "YYYYMMM D, h:mm a").unix();
                //   that.bookingTime.end = moment(string2, "YYYYMMM D, h:mm a").unix();
                // }
                that.bookingTime.timeType = "custom";
                that.getDeskItem(e.target.id);
                if (that.showList) {
                  that.showDeskInfo = false; //显示book it
                  that.showDeskDetail = true;
                } else {
                  if (that.showTodayBtn) {
                    that.showDeskInfo = true; //显示按钮组
                    that.showDeskDetail = true;
                  } else {
                    that.showDeskInfo = true; //显示按钮组
                    that.showDeskDetail = true;
                  }
                }
              } else {
                //DESK
                if (e.target.cannotClick) {
                  return;
                }
                if (!item.active) {
                  console.log("====33333 =====");
                  return;
                } else {
                  let shouldReturn = false;
                  console.log("====44444 =====");
                  // =====================带有时间范围限制=======================
                  let timeHasValue = true;
                  let workingHourStartTime = localStorage.getItem("workingHourStartTime");
                  let workingHourEndTime = localStorage.getItem("workingHourEndTime");
                  if (
                    workingHourStartTime == null ||
                    workingHourStartTime == "" ||
                    workingHourStartTime == "undefined" ||
                    workingHourEndTime == "" ||
                    workingHourEndTime == "undefined" ||
                    workingHourEndTime == null
                  ) {
                    timeHasValue = false;
                  }
                  let availBtns = that.$refs["searchComponent"].availBtns;
                  let activeBtn = ""; //1:Today > End of day   2:Hourly  3:Start Date End Date
                  availBtns.map(item => {
                    if (item.active == "active") {
                      activeBtn = item.value;
                    }
                  });
                  if (timeHasValue) {
                    // 当前meeting设置了desk预订的时间范围
                    console.log("====11111111111!====");
                    console.log("that.startTime", that.startTime);
                    let d = new Date();
                    d.setSeconds(0);
                    let dyear;
                    let month;
                    let date;
                    let startTimeAndYear;
                    let topBarMonth;
                    let topBarDate;
                    let topBarDateUnix;
                    let futureUnix; //用户加30分钟加到第二天或则后面的日期，比较的时候就不能用当前时间currentUnix而是左上角时间
                    if (timezone) {
                      topBarMonth = moment
                        .tz(startTimeAndYear, "YYYY-MMM D, h:mm a", timezone)
                        .format("M");
                    } else {
                      topBarMonth = moment(startTimeAndYear, "YYYY-MMM D, h:mm a", timezone).format(
                        "M"
                      );
                    }
                    if (timezone) {
                      dyear = String(moment.tz(d, timezone).format("YYYY"));
                      month = String(moment.tz(d, timezone).format("M"));
                      date = String(moment.tz(d, timezone).format("D"));
                      startTimeAndYear = dyear + "-" + that.startTime;
                      topBarMonth = moment
                        .tz(startTimeAndYear, "YYYY-MMM D, h:mm a", timezone)
                        .format("M");
                      topBarDate = moment
                        .tz(startTimeAndYear, "YYYY-MMM D, h:mm a", timezone)
                        .format("D");
                      topBarDateUnix = moment
                        .tz(startTimeAndYear, "YYYY-MMM D, h:mm a", timezone)
                        .unix();
                    } else {
                      dyear = String(moment(d).format("YYYY"));
                      month = String(moment(d).format("M"));
                      date = String(moment(d).format("D"));
                      startTimeAndYear = dyear + "-" + that.startTime;
                      topBarMonth = moment(startTimeAndYear, "YYYY-MMM D, h:mm a").format("M");
                      topBarDate = moment(startTimeAndYear, "YYYY-MMM D, h:mm a").format("D");
                      topBarDateUnix = moment(startTimeAndYear, "YYYY-MMM D, h:mm a").unix();
                    }
                    console.log("year:", dyear);
                    console.log("month:", month);
                    console.log("date:", date);
                    console.log("startTimeAndYear:", startTimeAndYear);
                    console.log("topBarMonth:", topBarMonth);
                    console.log("topBarDate:", topBarDate);
                    console.log("topBarDateUnix:", topBarDateUnix);
                    let condition = false;
                    condition = topBarDate != date || topBarMonth != month;
                    month = topBarMonth > month ? topBarMonth : month;
                    date = topBarDate > date ? topBarDate : date;
                    let dateString = dyear + "-" + month + "-" + date;
                    console.log("dateString:", dateString);
                    let currentUnix;
                    let startTimeLocalUnix;
                    let endTimeLocalUnix;
                    if (timezone) {
                      console.log("走timezone");
                      console.log("workingHourStartTime:", workingHourStartTime);
                      console.log("workingHourEndTime:", workingHourEndTime);
                      let workingHourStartTimeAndYear = dateString + "-" + workingHourStartTime;
                      let workingHourEndTimeAndYear = dateString + "-" + workingHourEndTime;
                      console.log("workingHourStartTimeAndYear:", workingHourStartTimeAndYear);
                      console.log("workingHourEndTimeAndYear:", workingHourEndTimeAndYear);
                      if (that.showTodayBtn) {
                        let time = dyear + that.startTime;
                        console.log("time:", time);
                        currentUnix = moment.tz(time, "YYYYMMM D, h:mm a", timezone).unix();
                      } else {
                        currentUnix = moment(d)
                          .tz(timezone)
                          .unix();
                      }
                      // currentUnix = moment(d)
                      //   .tz(timezone)
                      //   .unix();
                      startTimeLocalUnix = moment
                        .tz(workingHourStartTimeAndYear, "YYYY-M-D-HH:mm", timezone)
                        .unix();
                      endTimeLocalUnix = moment
                        .tz(workingHourEndTimeAndYear, "YYYY-M-D-HH:mm", timezone)
                        .unix();
                      // startTimeLocalUnix = moment
                      //   .tz(workingHourStartTime, "HH:mm", timezone)
                      //   .unix();
                      // endTimeLocalUnix = moment
                      //   .tz(workingHourEndTime, "HH:mm", timezone)
                      //   .unix();
                    } else {
                      console.log("不走timezone");
                      if (that.showTodayBtn) {
                        let time = dyear + that.startTime;
                        console.log("time:", time);
                        currentUnix = moment(time, "YYYYMMM D, h:mm a").unix();
                      } else {
                        currentUnix = moment(d).unix();
                      }
                      // currentUnix = moment(d).unix();
                      startTimeLocalUnix = moment(workingHourStartTime, "HH:mm")
                        .local()
                        .unix();
                      endTimeLocalUnix = moment(workingHourEndTime, "HH:mm")
                        .local()
                        .unix();
                    }
                    console.log("condition：", condition);
                    if (condition) {
                      //如果左上角时间不是当天的时间(用户可能+30min按了很多次按到了明天)
                      currentUnix = topBarDateUnix;
                    }
                    console.log("startTimeLocalUnix:", startTimeLocalUnix);
                    console.log("endTimeLocalUnix:", endTimeLocalUnix);

                    // let startTimeLocalUnix = moment(workingHourStartTime, 'HH:mm').local().unix();
                    // let endTimeLocalUnix = moment(workingHourEndTime, 'HH:mm').local().unix();
                    that.bookingTime = {};
                    console.log("====showDate~~~~~~~~~~~~~~~ =====", that.showDate);
                    // if(currentUnix>endTimeLocalUnix){
                    //   let msg = "Cannot book this desk at this time!"
                    //   that.$confirm({title: "Confirmation", message: msg, show: true})
                    //   shouldReturn = true;
                    //   return
                    // }
                    if (that.showDate) {
                      console.log("走showDate");
                      // 用户是自定义时间(Custom Timeframe)后点击的地图
                      let string = that.year + that.startTime;
                      let string2 = that.year + that.endTime;
                      let stringUnix;
                      let stringUnixStartOfDay;
                      // let stringUnix = moment(string, "YYYYMMM D, h:mm a").unix();
                      // let stringUnixStartOfDay = moment(string, "YYYYMMM D, h:mm a").startOf('day').unix();

                      that.bookingTime = {};
                      let d = new Date();
                      d.setSeconds(0);
                      let timezone = localStorage.getItem("timezone");
                      let currentDateUnixStartOfDay;
                      if (timezone) {
                        currentDateUnixStartOfDay = moment(d)
                          .tz(timezone)
                          .startOf("day")
                          .unix();
                        stringUnix = moment.tz(string, "YYYYMMM D, h:mm a", timezone).unix();
                        stringUnixStartOfDay = moment
                          .tz(string, "YYYYMMM D, h:mm a", timezone)
                          .startOf("day")
                          .unix();
                      } else {
                        currentDateUnixStartOfDay = moment(d)
                          .startOf("day")
                          .unix();
                        stringUnix = moment(string, "YYYYMMM D, h:mm a").unix();
                        stringUnixStartOfDay = moment(string, "YYYYMMM D, h:mm a")
                          .startOf("day")
                          .unix();
                      }
                      console.log(
                        "==== currentDateUnixStartOfDay!!!!=====",
                        currentDateUnixStartOfDay
                      );
                      //一. 当前选择的时间是当天
                      if (stringUnixStartOfDay == currentDateUnixStartOfDay) {
                        console.log("====Custom time frame 当前选择的时间是当天=====");
                        // 1.当前选择的时间小于workingHourStartTime
                        if (stringUnix < startTimeLocalUnix) {
                          console.log("==== 情况1=====");
                          that.bookingTime.start = startTimeLocalUnix;
                          that.bookingTime.end = endTimeLocalUnix;
                        } else if (
                          stringUnix >= startTimeLocalUnix &&
                          stringUnix < endTimeLocalUnix
                        ) {
                          console.log("==== 情况2=====");
                          // 2.当前选择的时间在workingHourStartTime和workingHourEndTime之间
                          if (that.timezone) {
                            that.bookingTime.start = moment
                              .tz(string, "YYYYMMM D, h:mm a", timezone)
                              .unix();
                          } else {
                            that.bookingTime.start = moment(string, "YYYYMMM D, h:mm a").unix();
                          }
                          // that.bookingTime.start = moment(string, "YYYYMMM D, h:mm a").unix();
                          that.bookingTime.end = endTimeLocalUnix;
                        } else {
                          console.log("==== 情况3=====");
                          // 3.当前选择的时间大于workingHourEndTime
                          let msg = "Cannot book this desk at this time!";
                          that.$confirm({
                            title: "Confirmation",
                            message: msg,
                            show: true
                          });
                          shouldReturn = true;
                          return;
                        }
                      } else {
                        console.log("==== =====");
                        //二. 当前选择的时间是不是当天

                        console.log("====string!!!!!!!!! =====", string);
                        console.log("====string2!!!!!!!!! =====", string2);
                        console.log("==== dateDialog=====", that.dateDialog);
                        // custom 选的日期(年月日)+meeting限制的开始时间workingHourStartTime
                        let customYearAndWorkingStartTime = that.dateDialog + workingHourStartTime;
                        let customYearAndWorkingEndTime = that.dateDialog + workingHourEndTime;
                        let customYearAndWorkingStartTimeUnix;
                        let customYearAndWorkingEndTimeUnix;
                        if (timezone) {
                          customYearAndWorkingStartTimeUnix = moment
                            .tz(customYearAndWorkingStartTime, "YYYY-MM-DDHH:mm", timezone)
                            .unix();
                          customYearAndWorkingEndTimeUnix = moment
                            .tz(customYearAndWorkingEndTime, "YYYY-MM-DDHH:mm", timezone)
                            .unix();
                        } else {
                          customYearAndWorkingStartTimeUnix = moment(
                            customYearAndWorkingStartTime,
                            "YYYY-MM-DDHH:mm"
                          ).unix();
                          customYearAndWorkingEndTimeUnix = moment(
                            customYearAndWorkingEndTime,
                            "YYYY-MM-DDHH:mm"
                          ).unix();
                        }
                        // let customYearAndWorkingStartTimeUnix = moment(customYearAndWorkingStartTime, 'YYYY-MM-DDHH:mm').unix();
                        // let customYearAndWorkingEndTimeUnix = moment(customYearAndWorkingEndTime, 'YYYY-MM-DDHH:mm').unix();
                        console.log("====stringUnix =====", stringUnix);
                        console.log(
                          "====customYearAndWorkingStartTimeUnix =====",
                          customYearAndWorkingStartTimeUnix
                        );
                        console.log(
                          "====customYearAndWorkingEndTimeUnix =====",
                          customYearAndWorkingEndTimeUnix
                        );
                        if (stringUnix <= customYearAndWorkingStartTimeUnix) {
                          console.log("====情况11 =====");
                          that.bookingTime.start = customYearAndWorkingStartTimeUnix;
                          that.bookingTime.end = customYearAndWorkingEndTimeUnix;
                        } else if (
                          customYearAndWorkingStartTimeUnix < stringUnix &&
                          stringUnix < customYearAndWorkingEndTimeUnix
                        ) {
                          console.log("====情况22 =====");
                          that.bookingTime.start = stringUnix;
                          that.bookingTime.end = customYearAndWorkingEndTimeUnix;
                        } else {
                          console.log("====情况33 =====");
                          let msg = "Cannot book this desk at this time!";
                          that.$confirm({
                            title: "Confirmation",
                            message: msg,
                            show: true
                          });
                          shouldReturn = true;
                          return;
                        }
                        // that.bookingTime.start = moment(string, "YYYYMMM D, h:mm a").unix();
                        // that.bookingTime.end = endTimeLocalUnix;
                      }

                      // that.bookingTime.start = moment(string, "YYYYMMM D, h:mm a").unix();
                      // if(that.showList){
                      //   that.bookingTime.end = moment(string2, "YYYYMMM D, h:mm a").unix();
                      // }else{
                      //   that.bookingTime.end = moment(string2, "YYYYMMM D, h:mm a").endOf('day').unix();
                      // }
                    } else {
                      console.log("不走showDate");
                      // 用户没有选择左侧的custom timeframe
                      // 用户是右侧搜索完(Find a space)或则没有其它操作（即没有打开Custom timeframe）然后点击地图
                      if (that.showList) {
                        console.log("====activeBtn !!!!!!!!!!!!=====", activeBtn);
                        if (activeBtn == 1) {
                          if (currentUnix >= startTimeLocalUnix) {
                            that.bookingTime.start = currentUnix;
                            that.bookingTime.end = endTimeLocalUnix;
                          } else {
                            that.bookingTime.start = startTimeLocalUnix;
                            that.bookingTime.end = endTimeLocalUnix;
                          }
                        } else {
                          let string = that.year + that.startTime;
                          let string2 = that.year + that.endTime;
                          that.bookingTime = {};
                          if (timezone) {
                            that.bookingTime.start = moment
                              .tz(string, "YYYYMMM D, h:mm a", timezone)
                              .unix();
                            that.bookingTime.end = moment
                              .tz(string2, "YYYYMMM D, h:mm a", timezone)
                              .unix();
                          } else {
                            that.bookingTime.start = moment(string, "YYYYMMM D, h:mm a").unix();
                            that.bookingTime.end = moment(string2, "YYYYMMM D, h:mm a").unix();
                          }
                          // that.bookingTime.start = moment(string, "YYYYMMM D, h:mm a").unix();
                          // that.bookingTime.end = moment(string2, "YYYYMMM D, h:mm a").unix();
                          that.bookingTime.timeType = "custom";
                        }
                      } else {
                        console.log("==== zheli!!!!!!!!!!=====");
                        console.log("==== currentUnix=====", currentUnix);
                        console.log("==== startTimeLocalUnix=====", startTimeLocalUnix);
                        if (condition && currentUnix >= endTimeLocalUnix) {
                          that.$confirm({
                            title: "Confirmation",
                            // message: "The current time cannot be queried",
                            message: "Cannot book this desk at this time!",
                            show: false
                          });
                          return;
                        }

                        if (currentUnix >= startTimeLocalUnix) {
                          that.bookingTime.start = currentUnix;
                          that.bookingTime.end = endTimeLocalUnix;
                        } else {
                          that.bookingTime.start = startTimeLocalUnix;
                          that.bookingTime.end = endTimeLocalUnix;
                        }
                      }
                      // if(currentUnix>=startTimeLocalUnix){
                      //   that.bookingTime.start = currentUnix;
                      //   that.bookingTime.end = endTimeLocalUnix;
                      // }else{
                      //   that.bookingTime.start = startTimeLocalUnix;
                      //   that.bookingTime.end = endTimeLocalUnix;
                      // }
                    }

                    console.log("==== bookingTime=====", that.bookingTime);
                  } else {
                    // 当前meeting没有设置desk预订的时间范围
                    console.log("====2222222222222222!====");
                    console.log("activeBtn:", activeBtn);
                    let string = that.year + that.startTime; //2022Aug 17, 4:00 am
                    let string2 = that.year + that.endTime;
                    console.log("string", string);
                    console.log("string2", string2);
                    that.bookingTime = {};
                    if (timezone) {
                      if (activeBtn == 1) {
                        // 打开了custom timeframe
                        if (that.showDate) {
                          that.bookingTime.start = moment
                            .tz(string, "YYYYMMM D, h:mm a", timezone)
                            .unix();
                        } else {
                          that.bookingTime.start = moment.tz(d, timezone).unix();
                        }
                      } else {
                        that.bookingTime.start = moment
                          .tz(string, "YYYYMMM D, h:mm a", timezone)
                          .unix();
                      }
                      // that.bookingTime.start = moment.tz(string, "YYYYMMM D, h:mm a", timezone).unix();
                    } else {
                      if (activeBtn == 1) {
                        // 打开了custom timeframe
                        if (that.showDate) {
                          that.bookingTime.start = moment(string, "YYYYMMM D, h:mm a").unix();
                        } else {
                          that.bookingTime.start = moment(d).unix();
                        }
                      } else {
                        that.bookingTime.start = moment(string, "YYYYMMM D, h:mm a").unix();
                      }
                      // that.bookingTime.start = moment(string, "YYYYMMM D, h:mm a").unix();
                    }
                    // that.bookingTime.start = moment(string, "YYYYMMM D, h:mm a").unix();
                    console.log("that.bookingTime.start", that.bookingTime.start);
                    if (that.showList) {
                      if (timezone) {
                        that.bookingTime.end = moment
                          .tz(string2, "YYYYMMM D, h:mm a", timezone)
                          .unix();
                      } else {
                        that.bookingTime.end = moment(string2, "YYYYMMM D, h:mm a").unix();
                      }
                      // that.bookingTime.end = moment(string2, "YYYYMMM D, h:mm a").unix();
                    } else {
                      if (timezone) {
                        that.bookingTime.end = moment
                          .tz(string2, "YYYYMMM D, h:mm a", timezone)
                          .endOf("day")
                          .unix();
                      } else {
                        that.bookingTime.end = moment(string2, "YYYYMMM D, h:mm a")
                          .endOf("day")
                          .unix();
                      }

                      // that.bookingTime.end = moment(
                      //   string2,
                      //   "YYYYMMM D, h:mm a"
                      // )
                      //   .endOf("day")
                      //   .unix();
                    }
                  }
                  if (shouldReturn) {
                    return;
                  }
                  // =====================0点到23：59分的做法=======================

                  // let string = that.year + that.startTime;
                  // let string2 = that.year + that.endTime;
                  // that.bookingTime = {};
                  // that.bookingTime.start = moment(string, "YYYYMMM D, h:mm a").unix();
                  // if(that.showList){
                  //   that.bookingTime.end = moment(string2, "YYYYMMM D, h:mm a").unix();
                  // }else{
                  //   that.bookingTime.end = moment(string2, "YYYYMMM D, h:mm a").endOf('day').unix();
                  // }
                  that.bookingTime.timeType = "custom";
                  that.getDeskItem(e.target.id);
                  if (that.showList) {
                    console.log("====1111 =====");
                    that.showDeskInfo = false; //显示book it
                  } else {
                    console.log("====2222 =====");
                    if (that.showTodayBtn) {
                      that.showDeskInfo = false; //显示book it
                    } else {
                      that.showDeskInfo = true; //显示按钮组
                    }
                  }
                  that.showDeskDetail = true;
                }
              }
            }
          });

          // that.showDeskDetail = true;
          // console.log(e.target);
          // console.log(e.target.id);
          // that.getDeskItem(e.target.id);
        }
      });
      //鼠标抬起事件
      canvas.on("mouse:up", function(e) {
        this.panning = false;
        endTime = new Date().getTime();
        let timeDiffer = endTime - startTime; //获取抬起时间
        this.isDrag = false;

        canvas.selection = true;
      });
      // 移动画布事件
      canvas.on("mouse:move", function(e) {
        var objects = canvas.getObjects("line");
        for (let i in objects) {
          canvas.remove(objects[i]);
        }
        canvas.renderAll();

        var pointer = canvas.getPointer(event);
        var posX = pointer.x;
        var posY = pointer.y;
        if (this.panning && e && e.e) {
          var delta = new fabric.Point(e.e.movementX, e.e.movementY);
          canvas.relativePan(delta);
        }
      });
      // 鼠标滚动画布放大缩小
      canvas.on("mouse:wheel", function(e) {
        let container = document.getElementById("spaceContainer");
        container.addEventListener("mousewheel", that.fn);
        // console.log(event);
        // console.log(canvas.getZoom());
        var zoom = (event.deltaY > 0 ? -0.2 : 0.2) + canvas.getZoom();
        zoom = Math.max(1, zoom); //最小为原来的1
        zoom = Math.min(20, zoom); //最大是原来的5倍
        var zoomPoint = new fabric.Point(event.offsetX, event.offsetY);

        canvas.zoomToPoint(zoomPoint, zoom);
      });

      canvas.on("object:selected", function(obj) {
        console.log("obj:", obj);
      });

      // canvas.on("mouse:dblclick", function (obj) {
      //   let items = canvas.getObjects();
      //   console.log("items:",items);
      //   let img = items[0];
      //   console.log("img:",img);
      //   img.top = 340;
      //   img.left = 300;
      //   img.originX = 'center';
      //   img.originY = 'center';
      //   canvas.renderAll();
      // });

      // canvas.defaultCursor = 'crosshair'; //默认光标改成十字
      canvas.hoverCursor = "pointer"; //悬浮光标改成手型
      canvas.preserveObjectStacking = true;

      canvas.renderAll();
    },
    zoomIn() {
      var center = canvas.getCenter();
      // console.log(canvas.getZoom(), center);
      var zoom = canvas.getZoom() + 0.2;
      zoom = Math.max(0.2, zoom); //最小为原来的1/10
      zoom = Math.min(20, zoom); //最大是原来的3倍
      var zoomPoint = new fabric.Point(center.left, center.top);
      // canvas.setZoom(zoom);
      canvas.zoomToPoint(zoomPoint, zoom);
    },
    zoomOut() {
      // console.log(canvas.getZoom());
      var center = canvas.getCenter();
      var zoom = canvas.getZoom() - 0.2;
      zoom = Math.max(0.2, zoom); //最小为原来的1/10
      zoom = Math.min(20, zoom); //最大是原来的3倍
      var zoomPoint = new fabric.Point(center.left, center.top);
      canvas.zoomToPoint(zoomPoint, zoom);
      // canvas.setZoom(zoom);
    },
    // zoomIn() {
    //   console.log(canvas.getZoom());
    //   var zoom = canvas.getZoom() + 0.2;
    //   zoom = Math.max(0.2, zoom); //最小为原来的1/10
    //   zoom = Math.min(10, zoom); //最大是原来的3倍
    //   // var zoomPoint = new fabric.Point(event.pageX, event.pageY);
    //   //
    //   canvas.setZoom(zoom);
    // },
    // zoomOut() {
    //   console.log(canvas.getZoom());
    //   var zoom = canvas.getZoom() - 0.2;
    //   zoom = Math.max(0.2, zoom); //最小为原来的1/10
    //   zoom = Math.min(10, zoom); //最大是原来的3倍
    //   // var zoomPoint = new fabric.Point(event.pageX, event.pageY);
    //   //
    //   canvas.setZoom(zoom);
    // },
    // 地图描desk
    drawDesk(filterData) {
      let that = this;
      if (filterData) {
        filterData.forEach((si, sk) => {
          that.deskData.forEach((item, k) => {
            if (si.id === item.id) {
              item.pointArrXY = [];
              if (item.points) {
                for (let i = 0; i < item.points.length; i++) {
                  let newI = item.points[i].split(",");
                  let obj = {};
                  if (!isNaN(newI[0]) && !isNaN(newI[1])) {
                    obj.x = newI[0];
                    obj.y = newI[1];
                    item.pointArrXY.push(obj);
                  }
                  // obj.x = newI[0];
                  // obj.y = newI[1];
                  // item.pointArrXY.push(obj);
                }
                // console.log('desk:item.pointArrXY:', item.pointArrXY);
                let pl = item.pointArrXY.length;
                // console.log(item.pointArrXY.length);
                if (pl > 3) {
                  let color = "";
                  if (si.active === false) {
                    color = "#8e8c8c";
                  } else if (si.active === true && si.busy === true) {
                    color = "#DF2E07";
                    if (that.client == "EA") {
                      color = "#3352E1";
                    }
                  } else if (si.active === true && si.busy === false) {
                    color = "#00D302";
                  }
                  item.pointArrXY.map(item => {
                    item.x =
                      item.x * num + Number(that.canvasW / 2) - Number((that.imageW * num) / 2);
                    item.y =
                      item.y * num + Number(that.canvasH / 2) - Number((that.imageH * num) / 2);
                  });
                  let polygon = new fabric.Polygon(item.pointArrXY, {
                    id: item.id,
                    name: item.name,
                    fill: color,
                    hasControls: false,
                    opacity: 0.5,
                    hasBorders: true,
                    strokeWidth: 0, // 边框大小
                    lockMovementX: true,
                    lockMovementY: true
                  });
                  canvas.add(polygon);
                  canvas.renderAll();
                }
              }
            }
          });
        });
      } else {
        if (that.desksStateData) {
          that.deskData.forEach((item, k) => {
            item.pointArrXY = [];
            item.noMatch = true;
            let length = that.desksStateData.length;
            for (let i = 0; i < length; i++) {
              let si = that.desksStateData[i];
              if (si.seat_id === item.id) {
                item.noMatch = false;
                item.pointArrXY = [];
                if (item.points) {
                  for (let i = 0; i < item.points.length; i++) {
                    let newI = item.points[i].split(",");
                    let obj = {};
                    if (!isNaN(newI[0]) && !isNaN(newI[1])) {
                      obj.x = newI[0];
                      obj.y = newI[1];
                      item.pointArrXY.push(obj);
                    }
                    // obj.x = newI[0];
                    // obj.y = newI[1];
                    // item.pointArrXY.push(obj);
                  }
                  // console.log('desk:item.pointArrXY:', item.pointArrXY);
                  let pl = item.pointArrXY.length;
                  // console.log(item.pointArrXY.length);
                  if (pl > 3) {
                    let path = "";
                    // console.log(item.pointArrXY[0].y, num, item.pointArrXY[0].y * num , that.canvasH, that.imageH,item.pointArrXY[0].y * num + that.canvasH/2 -(that.imageH * num /2));
                    let color = "";
                    if (si.active === false) {
                      color = "#8e8c8c";
                    } else if (si.active === true && si.busy === true) {
                      color = "#DF2E07";
                      if (that.client == "EA") {
                        color = "#3352E1";
                      }
                    } else if (si.active === true && si.busy === false) {
                      color = "#00D302";
                    }
                    // console.log('deskPath:',path);
                    item.pointArrXY.map(item => {
                      item.x =
                        item.x * num + Number(that.canvasW / 2) - Number((that.imageW * num) / 2);
                      item.y =
                        item.y * num + Number(that.canvasH / 2) - Number((that.imageH * num) / 2);
                    });
                    let polygon = new fabric.Polygon(item.pointArrXY, {
                      id: item.id,
                      name: item.name,
                      fill: color,
                      hasControls: false,
                      opacity: 0.5,
                      hasBorders: true,
                      strokeWidth: 0, // 边框大小
                      lockMovementX: true,
                      lockMovementY: true
                    });
                    canvas.add(polygon);
                    canvas.renderAll();
                  }
                }
                break;
              } else {
                item.noMatch = true;
              }
            }
          });
          that.deskData.map(item => {
            if (item.noMatch && item.points) {
              for (let i = 0; i < item.points.length; i++) {
                let newI = item.points[i].split(",");
                let obj = {};
                if (!isNaN(newI[0]) && !isNaN(newI[1])) {
                  obj.x = newI[0];
                  obj.y = newI[1];
                  item.pointArrXY.push(obj);
                }
              }
              let pl = item.pointArrXY.length;
              if (pl > 3) {
                item.pointArrXY.map(item => {
                  item.x =
                    item.x * num + Number(that.canvasW / 2) - Number((that.imageW * num) / 2);
                  item.y =
                    item.y * num + Number(that.canvasH / 2) - Number((that.imageH * num) / 2);
                });
                let polygon = new fabric.Polygon(item.pointArrXY, {
                  id: item.id,
                  name: item.name,
                  fill: "#8e8c8c",
                  hasControls: false,
                  opacity: 0.5,
                  hasBorders: true,
                  strokeWidth: 0, // 边框大小
                  lockMovementX: true,
                  lockMovementY: true,
                  cannotClick: true
                });
                canvas.add(polygon);
                canvas.renderAll();
              }
            }
          });
        }
      }
    },
    // drawRoom(filterData) {
    //   let that = this;
    //   if (filterData) {
    //     filterData.forEach((si, sk) => {
    //       that.roomData.forEach((item, k) => {
    //         if (item.resourceId && (si.resourceId === item.resourceId || si.resourceEmail === item.resourceId)) {
    //           item.pointArrXY = [];
    //           if (item.points) {
    //             for (let i = 0; i < item.points.length; i++) {
    //               let newI = item.points[i].split(",");
    //               let obj = {};
    //               if (!isNaN(newI[0]) && !isNaN(newI[1])) {
    //                 obj.x = newI[0];
    //                 obj.y = newI[1];
    //                 item.pointArrXY.push(obj);
    //               }
    //               // obj.x = newI[0];
    //               // obj.y = newI[1];
    //               // item.pointArrXY.push(obj);
    //             }
    //             let pl = item.pointArrXY.length;
    //             if (pl > 3) {
    //               if(item.active==false){
    //                 let color = "#878787";
    //                 item.pointArrXY.map(item => {
    //                   item.x = item.x * num + Number(that.canvasW / 2) - Number(that.imageW * num / 2);
    //                   item.y = item.y * num + Number(that.canvasH / 2) - Number(that.imageH * num / 2);
    //                 })
    //                 let polygon = new fabric.Polygon(item.pointArrXY, {
    //                   id: item.id,
    //                   name: item.name,
    //                   fill: color,
    //                   hasControls: false,
    //                   opacity: 0.5,
    //                   hasBorders: true,
    //                   strokeWidth: 0, // 边框大小
    //                   lockMovementX: true,
    //                   lockMovementY: true,
    //                   cannotClick: true
    //                 });
    //                 canvas.add(polygon);
    //                 canvas.renderAll();
    //               }else{
    //                 let color = '';
    //                 if (si.busy_time.length > 0) {
    //                   let startTimeUnix = moment(this.startTime, "MMM D, h:mm a").unix();
    //                   let endTimeUnix = moment(this.endTime, "MMM D, h:mm a").unix();
    //                   // 当前查看的结束时间小于房间预定的开始时间（分情况讨论）
    //                   if (endTimeUnix <= si.busy_time[0].start) {
    //                     let minutes = moment(si.busy_time[0].start, "X").diff(moment(startTimeUnix, "X"), 'minutes');
    //                     if (minutes > 30) {
    //                       that.state = '#00D302'
    //                     } else if (minutes == 30) {
    //                       that.state = '#FF8C00'
    //                     } else if (minutes < 30) {
    //                       that.state = '#DF2E07'
    //                     }
    //                   } else if (startTimeUnix >= si.busy_time[0].end) {// 当前查看的开始时间大于房间预定的结束时间（肯定是绿色可订的）
    //                     that.state = '#00D302';
    //                   } else {// 当前查看的时间和房间预定的时间有交集
    //                     that.state = '#DF2E07'
    //                   }
    //                 }
    //                 else {
    //                   that.state = '#00D302'
    //                 }
    //                 color = that.state;
    //                 item.pointArrXY.map(item => {
    //                   item.x = item.x * num + Number(that.canvasW / 2) - Number(that.imageW * num / 2);
    //                   item.y = item.y * num + Number(that.canvasH / 2) - Number(that.imageH * num / 2);
    //                 })
    //                 let polygon = new fabric.Polygon(item.pointArrXY, {
    //                   id: item.id,
    //                   name: item.name,
    //                   fill: color,
    //                   hasControls: false,
    //                   opacity: 0.5,
    //                   hasBorders: true,
    //                   strokeWidth: 0, // 边框大小
    //                   lockMovementX: true,
    //                   lockMovementY: true
    //                 });
    //                 canvas.add(polygon);
    //                 canvas.renderAll();
    //               }
    //             }
    //           }
    //         }
    //       })
    //     })
    //   } else {
    //     if (that.roomsStateData) {
    //       console.log("进来----------------------")
    //       console.log("that.roomsStateData", that.roomsStateData)
    //       that.roomData.map((item, k) => {
    //         let length = that.roomsStateData.length;
    //         for (let i = 0; i < length; i++) {
    //           let si = that.roomsStateData[i];
    //           if (item.resourceId && (si.resourceId === item.resourceId || si.resourceEmail === item.resourceId)) {
    //             item.match = true;
    //             item.pointArrXY = [];
    //             if (item.points) {
    //               for (let i = 0; i < item.points.length; i++) {
    //                 let newI = item.points[i].split(",");
    //                 let obj = {};
    //                 if (!isNaN(newI[0]) && !isNaN(newI[1])) {
    //                   obj.x = newI[0];
    //                   obj.y = newI[1];
    //                   item.pointArrXY.push(obj);
    //                 }
    //               }
    //               let pl = item.pointArrXY.length;
    //               if (pl > 3) {
    //                 let color = '';
    //                 if(item.active==false){
    //                   console.log("item没激活：",item)
    //                   that.state = '#878787'
    //                   color = that.state;
    //                   item.pointArrXY.map(item => {
    //                     item.x = item.x * num + Number(that.canvasW / 2) - Number(that.imageW * num / 2);
    //                     item.y = item.y * num + Number(that.canvasH / 2) - Number(that.imageH * num / 2);
    //                   })
    //                   let polygon = new fabric.Polygon(item.pointArrXY, {
    //                     id: item.id,
    //                     name: item.name,
    //                     fill: color,
    //                     hasControls: false,
    //                     opacity: 0.5,
    //                     hasBorders: true,
    //                     strokeWidth: 0, // 边框大小
    //                     lockMovementX: true,
    //                     lockMovementY: true,
    //                     cannotClick: true
    //                   });
    //                   canvas.add(polygon);
    //                   canvas.renderAll();
    //                 }else{
    //                   console.log("item激活：",item)
    //                   if (si.busy_time.length > 0) {
    //                     let startTimeUnix = moment(this.startTime, "MMM D, h:mm a").unix();
    //                     let endTimeUnix = moment(this.endTime, "MMM D, h:mm a").unix();
    //                     // let minutes = moment(si.busy_time[0].start, "X").diff(moment(startTimeUnix, "X"), 'minutes');
    //                     // console.log("si:", si.resourceName);
    //                     // if (minutes == 0) {
    //                     //   that.state = '#DF2E07'
    //                     // } else if (minutes == 30) {
    //                     //   that.state = '#FF8C00'
    //                     // } else {
    //                     //   that.state = '#00D302'
    //                     // }
    //                     if (endTimeUnix <= si.busy_time[0].start) {
    //                       let minutes = moment(si.busy_time[0].start, "X").diff(moment(startTimeUnix, "X"), 'minutes');
    //                       if (minutes > 30) {
    //                         that.state = '#00D302'
    //                       } else if (minutes == 30) {
    //                         that.state = '#FF8C00'
    //                       } else if (minutes < 30) {
    //                         that.state = '#DF2E07'
    //                       }
    //                     } else if (startTimeUnix >= si.busy_time[0].end) {
    //                       that.state = '#00D302';
    //                     } else {
    //                       that.state = '#DF2E07'
    //                     }
    //                   } else {
    //                     that.state = '#00D302'
    //                   }
    //                   color = that.state;
    //                   item.pointArrXY.map(item => {
    //                     item.x = item.x * num + Number(that.canvasW / 2) - Number(that.imageW * num / 2);
    //                     item.y = item.y * num + Number(that.canvasH / 2) - Number(that.imageH * num / 2);
    //                   })
    //                   let polygon = new fabric.Polygon(item.pointArrXY, {
    //                     id: item.id,
    //                     name: item.name,
    //                     fill: color,
    //                     hasControls: false,
    //                     opacity: 0.5,
    //                     hasBorders: true,
    //                     strokeWidth: 0, // 边框大小
    //                     lockMovementX: true,
    //                     lockMovementY: true
    //                   });
    //                   canvas.add(polygon);
    //                   canvas.renderAll();
    //                 }
    //               }
    //             }
    //             break;
    //           }
    //         }
    //       })
    //       console.log("roomData!!!!!!:", that.roomData)
    //       that.roomData.map(item => {
    //         item.pointArrXY = []
    //         if (!item.match && item.points) {
    //           for (let i = 0; i < item.points.length; i++) {
    //             let newI = item.points[i].split(",");
    //             let obj = {};
    //             if (!isNaN(newI[0]) && !isNaN(newI[1])) {
    //               obj.x = newI[0];
    //               obj.y = newI[1];
    //               item.pointArrXY.push(obj);
    //             }
    //           }
    //           let pl = item.pointArrXY.length;
    //           if (pl > 3) {
    //             item.pointArrXY.map(item => {
    //               item.x = item.x * num + Number(that.canvasW / 2) - Number(that.imageW * num / 2);
    //               item.y = item.y * num + Number(that.canvasH / 2) - Number(that.imageH * num / 2);
    //             })
    //             let polygon = new fabric.Polygon(item.pointArrXY, {
    //               id: item.id,
    //               name: item.name,
    //               fill: '#878787',
    //               hasControls: false,
    //               opacity: 0.5,
    //               hasBorders: true,
    //               strokeWidth: 0, // 边框大小
    //               lockMovementX: true,
    //               lockMovementY: true,
    //               cannotClick: true
    //             });
    //             canvas.add(polygon);
    //             canvas.renderAll();
    //           }
    //         }
    //       })
    //     }
    //   }
    //
    //
    //   // let isEmpty = JSON.stringify(this.currentItem) == "{}";
    //   // if (!isEmpty && this.currentItem.type == 'room') {
    //   //   this.getPath(this.currentItem)
    //   // }
    // },
    // 判断两个时间段有无交集
    timeIntersection(start1, end1, start2, end2) {
      console.log("start1：", start1);
      console.log("end1：", end1);
      console.log("start2：", start2);
      console.log("end2：", end2);
      if (end1 <= start2) {
        return false; //无交集
      } else if (start1 >= end2) {
        return false; //无交集
      } else {
        return true; //有交集
      }
    },
    drawRoom(filterData) {
      let that = this;
      if (filterData) {
        filterData.forEach((si, sk) => {
          that.roomData.forEach((item, k) => {
            if (
              item.resourceId &&
              (si.resourceId === item.resourceId || si.resourceEmail === item.resourceId)
            ) {
              item.pointArrXY = [];
              if (item.points) {
                for (let i = 0; i < item.points.length; i++) {
                  let newI = item.points[i].split(",");
                  let obj = {};
                  if (!isNaN(newI[0]) && !isNaN(newI[1])) {
                    obj.x = newI[0];
                    obj.y = newI[1];
                    item.pointArrXY.push(obj);
                  }
                  // obj.x = newI[0];
                  // obj.y = newI[1];
                  // item.pointArrXY.push(obj);
                }
                let pl = item.pointArrXY.length;
                if (pl > 3) {
                  if (item.active == false) {
                    let color = "#878787";
                    item.pointArrXY.map(item => {
                      item.x =
                        item.x * num + Number(that.canvasW / 2) - Number((that.imageW * num) / 2);
                      item.y =
                        item.y * num + Number(that.canvasH / 2) - Number((that.imageH * num) / 2);
                    });
                    let polygon = new fabric.Polygon(item.pointArrXY, {
                      id: item.id,
                      name: item.name,
                      fill: color,
                      hasControls: false,
                      opacity: 0.5,
                      hasBorders: true,
                      strokeWidth: 0, // 边框大小
                      lockMovementX: true,
                      lockMovementY: true,
                      cannotClick: true
                    });
                    canvas.add(polygon);
                    canvas.renderAll();
                  } else {
                    let color = "";
                    if (si.busy_time.length > 0) {
                      let startTimeUnix;
                      let endTimeUnix;
                      if (this.timezone) {
                        startTimeUnix = moment
                          .tz(this.startTime, "MMM D, h:mm a", this.timezone)
                          .unix();
                        endTimeUnix = moment
                          .tz(this.endTime, "MMM D, h:mm a", this.timezone)
                          .unix();
                      } else {
                        startTimeUnix = moment(this.startTime, "MMM D, h:mm a").unix();
                        endTimeUnix = moment(this.endTime, "MMM D, h:mm a").unix();
                      }
                      // let startTimeUnix = moment(this.startTime, "MMM D, h:mm a").unix();
                      // let endTimeUnix = moment(this.endTime, "MMM D, h:mm a").unix();
                      let length = si.busy_time.length;
                      let hasIntersection = false; //查询的时间区间是否有冲突
                      for (let i = 0; i < length; i++) {
                        // 如果左上角时间区间和room的busy时间区间有重合，则显示红色
                        let item = si.busy_time[i];
                        hasIntersection = this.timeIntersection(
                          startTimeUnix,
                          endTimeUnix,
                          item.start,
                          item.end
                        );
                        if (hasIntersection == true) {
                          break;
                        }
                      }
                      console.log("Draw-room-hasIntersection：", hasIntersection);
                      if (hasIntersection) {
                        that.state = "#DF2E07";
                        if (that.client == "EA") {
                          that.state = "#3352E1";
                        }
                      } else {
                        // 如果左上角时间区间和room的busy时间区间没有重合，则判断是显示绿色还是黄色
                        let d = new Date(); //当前时间
                        d.setSeconds(0);
                        let timezone = localStorage.getItem("timezone");
                        let dUnix;
                        let dUnixEnd;
                        if (timezone) {
                          dUnix = moment(d)
                            .tz(timezone)
                            .unix(); //当前时间的时间戳
                          dUnixEnd = moment(d)
                            .tz(timezone)
                            .add(15, "minutes")
                            .unix(); //当前时间的时间戳加15分钟
                        } else {
                          dUnix = moment(d).unix(); //当前时间的时间戳
                          dUnixEnd = moment(d)
                            .add(15, "minutes")
                            .unix(); //当前时间的时间戳加15分钟
                        }
                        // let dUnix = moment(d).unix();//当前时间的时间戳
                        // let dUnixEnd = moment(d).add(15, 'minutes').unix();//当前时间的时间戳加15分钟
                        if (dUnix >= startTimeUnix && dUnix <= endTimeUnix) {
                          //如果当前时间在左上角时间区间内
                          console.log("111");
                          let hasIntersection = false;
                          for (let i = 0; i < length; i++) {
                            // 如果当前时间和当前时间加15分钟生成的新区间和room的busy时间区间有重合，则显示黄色，否则显示绿色
                            let item = si.busy_time[i];
                            hasIntersection = this.timeIntersection(
                              dUnix,
                              dUnixEnd,
                              item.start,
                              item.end
                            );
                            if (hasIntersection == true) {
                              break;
                            }
                          }
                          if (hasIntersection == true) {
                            that.state = show15
                              ? "#FF8C00"
                              : that.client == "EA"
                              ? "#99A8F1"
                              : "#00D302";
                          } else {
                            that.state = "#00D302";
                          }
                        } else {
                          console.log("222");
                          let hasIntersection = false;
                          for (let i = 0; i < length; i++) {
                            // 如果左上角时间区间和room的busy时间区间有重合，则显示黄色，否则显示绿色
                            let item = si.busy_time[i];
                            hasIntersection = this.timeIntersection(
                              startTimeUnix,
                              endTimeUnix,
                              item.start,
                              item.end
                            );
                            if (hasIntersection == true) {
                              break;
                            }
                          }
                          if (hasIntersection == true) {
                            // that.state = show15 ? '#FF8C00' : '#00D302';
                            that.state = show15
                              ? "#FF8C00"
                              : that.client == "EA"
                              ? "#99A8F1"
                              : "#00D302";
                          } else {
                            that.state = "#00D302";
                          }
                        }
                      }
                    } else {
                      that.state = "#00D302";
                    }
                    color = that.state;
                    item.pointArrXY.map(item => {
                      item.x =
                        item.x * num + Number(that.canvasW / 2) - Number((that.imageW * num) / 2);
                      item.y =
                        item.y * num + Number(that.canvasH / 2) - Number((that.imageH * num) / 2);
                    });
                    let polygon = new fabric.Polygon(item.pointArrXY, {
                      id: item.id,
                      name: item.name,
                      fill: color,
                      hasControls: false,
                      opacity: 0.5,
                      hasBorders: true,
                      strokeWidth: 0, // 边框大小
                      lockMovementX: true,
                      lockMovementY: true
                    });
                    canvas.add(polygon);
                    canvas.renderAll();
                  }
                }
              }
            }
          });
        });
      } else {
        if (that.roomsStateData) {
          that.roomData.map((item, k) => {
            item.match = false;
            let length = that.roomsStateData.length;
            for (let i = 0; i < length; i++) {
              let si = that.roomsStateData[i];
              if (
                item.resourceId &&
                (si.resourceId === item.resourceId || si.resourceEmail === item.resourceId)
              ) {
                item.match = true;
                item.pointArrXY = [];
                if (item.points) {
                  for (let i = 0; i < item.points.length; i++) {
                    let newI = item.points[i].split(",");
                    let obj = {};
                    if (!isNaN(newI[0]) && !isNaN(newI[1])) {
                      obj.x = newI[0];
                      obj.y = newI[1];
                      item.pointArrXY.push(obj);
                    }
                  }
                  let pl = item.pointArrXY.length;
                  if (pl > 3) {
                    let color = "";
                    if (item.active == false) {
                      console.log("item没有激活：", item);
                      that.state = "#878787";
                      color = that.state;
                      item.pointArrXY.map(item => {
                        item.x =
                          item.x * num + Number(that.canvasW / 2) - Number((that.imageW * num) / 2);
                        item.y =
                          item.y * num + Number(that.canvasH / 2) - Number((that.imageH * num) / 2);
                      });
                      let polygon = new fabric.Polygon(item.pointArrXY, {
                        id: item.id,
                        name: item.name,
                        fill: color,
                        hasControls: false,
                        opacity: 0.5,
                        hasBorders: true,
                        strokeWidth: 0, // 边框大小
                        lockMovementX: true,
                        lockMovementY: true,
                        cannotClick: true
                      });
                      canvas.add(polygon);
                      canvas.renderAll();
                    } else {
                      if (si.busy_time.length > 0) {
                        console.log("==== 占用 =====");
                        let timezone = localStorage.getItem("timezone");
                        let startTimeUnix;
                        let endTimeUnix;
                        if (timezone) {
                          startTimeUnix = moment
                            .tz(this.startTime, "MMM D, h:mm a", timezone)
                            .unix();
                          endTimeUnix = moment.tz(this.endTime, "MMM D, h:mm a", timezone).unix();
                        } else {
                          startTimeUnix = moment(this.startTime, "MMM D, h:mm a").unix();
                          endTimeUnix = moment(this.endTime, "MMM D, h:mm a").unix();
                        }
                        // let startTimeUnix = moment(this.startTime, "MMM D, h:mm a").unix();
                        // let endTimeUnix = moment(this.endTime, "MMM D, h:mm a").unix();
                        let length = si.busy_time.length;
                        let hasIntersection = false;
                        for (let i = 0; i < length; i++) {
                          // 如果左上角时间区间和room的busy时间区间有重合，则显示红色
                          let item = si.busy_time[i];
                          hasIntersection = this.timeIntersection(
                            startTimeUnix,
                            endTimeUnix,
                            item.start,
                            item.end
                          );
                          if (hasIntersection == true) {
                            break;
                          }
                        }
                        console.log("hasIntersection：", hasIntersection);
                        if (hasIntersection) {
                          that.state = "#DF2E07";
                          if (that.client == "EA") {
                            that.state = "#3352E1";
                          }
                        } else {
                          console.log("z111111111");
                          // 如果左上角时间区间和room的busy时间区间没有重合，则判断是显示绿色还是黄色
                          let d = new Date(); //当前时间
                          d.setSeconds(0);
                          let timezone = localStorage.getItem("timezone");
                          let dUnix;
                          let dUnixEnd;
                          if (timezone) {
                            console.log("z2222222222");
                            dUnix = moment(d)
                              .tz(timezone)
                              .unix(); //当前时间的时间戳
                            dUnixEnd = moment(d)
                              .tz(timezone)
                              .add(15, "minutes")
                              .unix(); //当前时间的时间戳加15分钟
                          } else {
                            dUnix = moment(d).unix(); //当前时间的时间戳
                            dUnixEnd = moment(d)
                              .add(15, "minutes")
                              .unix(); //当前时间的时间戳加15分钟
                          }
                          // let dUnix = moment(d).unix();//当前时间的时间戳
                          // let dUnixEnd = moment(d).add(15, 'minutes').unix();//当前时间的时间戳加15分钟
                          if (dUnix >= startTimeUnix && dUnix <= endTimeUnix) {
                            //如果当前时间在左上角时间区间内
                            console.log("z333");
                            let hasIntersection = false;
                            for (let i = 0; i < length; i++) {
                              // 如果当前时间和当前时间加15分钟生成的新区间和room的busy时间区间有重合，则显示黄色，否则显示绿色
                              let item = si.busy_time[i];
                              hasIntersection = this.timeIntersection(
                                dUnix,
                                dUnixEnd,
                                item.start,
                                item.end
                              );
                              if (hasIntersection == true) {
                                break;
                              }
                            }
                            if (hasIntersection == true) {
                              // that.state = show15 ? '#FF8C00' : '#00D302';
                              that.state = show15
                                ? "#FF8C00"
                                : that.client == "EA"
                                ? "#99A8F1"
                                : "#00D302";
                            } else {
                              that.state = "#00D302";
                            }
                          } else {
                            console.log("z444");
                            let hasIntersection = false;
                            for (let i = 0; i < length; i++) {
                              // 如果左上角时间区间和room的busy时间区间有重合，则显示黄色，否则显示绿色
                              let item = si.busy_time[i];
                              hasIntersection = this.timeIntersection(
                                startTimeUnix,
                                endTimeUnix,
                                item.start,
                                item.end
                              );
                              if (hasIntersection == true) {
                                break;
                              }
                            }
                            if (hasIntersection == true) {
                              that.state = show15
                                ? "#FF8C00"
                                : that.client == "EA"
                                ? "#99A8F1"
                                : "#00D302";
                            } else {
                              that.state = "#00D302";
                            }
                          }
                        }
                      } else {
                        console.log("z555");
                        that.state = "#00D302";
                      }
                      color = that.state;
                      item.pointArrXY.map(item => {
                        item.x =
                          item.x * num + Number(that.canvasW / 2) - Number((that.imageW * num) / 2);
                        item.y =
                          item.y * num + Number(that.canvasH / 2) - Number((that.imageH * num) / 2);
                      });
                      let polygon = new fabric.Polygon(item.pointArrXY, {
                        id: item.id,
                        name: item.name,
                        fill: color,
                        hasControls: false,
                        opacity: 0.5,
                        hasBorders: true,
                        strokeWidth: 0, // 边框大小
                        lockMovementX: true,
                        lockMovementY: true
                      });
                      canvas.add(polygon);
                      canvas.renderAll();
                    }
                  }
                }
                break;
              }
            }
          });

          that.roomData.map(item => {
            item.pointArrXY = [];
            if (!item.match && item.points && item.resourceId != "") {
              for (let i = 0; i < item.points.length; i++) {
                let newI = item.points[i].split(",");
                let obj = {};
                if (!isNaN(newI[0]) && !isNaN(newI[1])) {
                  obj.x = newI[0];
                  obj.y = newI[1];
                  item.pointArrXY.push(obj);
                }
              }
              let pl = item.pointArrXY.length;
              if (pl > 3) {
                item.pointArrXY.map(item => {
                  item.x =
                    item.x * num + Number(that.canvasW / 2) - Number((that.imageW * num) / 2);
                  item.y =
                    item.y * num + Number(that.canvasH / 2) - Number((that.imageH * num) / 2);
                });
                let polygon = new fabric.Polygon(item.pointArrXY, {
                  id: item.id,
                  name: item.name,
                  fill: "#8e8c8c",
                  hasControls: false,
                  opacity: 0.5,
                  hasBorders: true,
                  strokeWidth: 0, // 边框大小
                  lockMovementX: true,
                  lockMovementY: true,
                  cannotClick: true
                });
                canvas.add(polygon);
                canvas.renderAll();
              }
            }
          });
        }
      }

      // let isEmpty = JSON.stringify(this.currentItem) == "{}";
      // if (!isEmpty && this.currentItem.type == 'room') {
      //   this.getPath(this.currentItem)
      // }
    },
    drawSensors(data) {
      if (this.client != "WarnerMedia") {
        return;
      }
      let that = this;
      console.log("====Sensors =====", data);
      this.roomData.map(item => {
        let length = data.length;
        for (let i = 0; i < length; i++) {
          let jtem = data[i];
          if (item.sensorId == jtem.sensorId && item.points) {
            let pointsLength = item.points.length;
            let pointArrXY = [];
            for (let i = 0; i < pointsLength; i++) {
              let newI = item.points[i].split(",");
              let obj = {};
              if (!isNaN(newI[0]) && !isNaN(newI[1])) {
                obj.x = newI[0];
                obj.y = newI[1];
                pointArrXY.push(obj);
              }
            }
            pointArrXY.map(item => {
              item.x = item.x * num + Number(that.canvasW / 2) - Number((that.imageW * num) / 2);
              item.y = item.y * num + Number(that.canvasH / 2) - Number((that.imageH * num) / 2);
            });
            console.log("pointArrXY", pointArrXY);
            let color = "";
            let fill = "#ffffff00";
            let percent = localStorage.getItem("maxDensityPercentage") || 1;
            if (jtem.total_count > jtem.maximumDensity * percent) {
              color = "#96069a";
              fill = "#ee8df1";
            } else {
              if (jtem.isOccupied == 1) {
                color = "#ef041f";
                fill = "#ef041f";
              } else if (jtem.isOccupied == -1) {
                color = "#ffffff00";
              } else {
                color = "#238906";
                fill = "#e7fcda";
                // fill = '#c9f8bc'
              }
            }
            let pl = pointArrXY.length;
            if (pl > 3) {
              let polygon = new fabric.Polygon(pointArrXY, {
                id: item.id,
                name: item.name,
                stroke: color,
                strokeWidth: 2,
                opacity: 0.5,
                fill: fill,
                hasControls: false,
                hasBorders: true,
                lockMovementX: true,
                lockMovementY: true,
                cannotClick: true
              });
              canvas.add(polygon);
              canvas.renderAll();
            }
            break;
          }
        }
      });
    },
    getDeskItem(id, ignoreActive = false) {
      console.log("触发了getDeskItem", id);
      //ignoreActive为true时，右侧直接展示（用于点击日历）
      //ignoreActive为false时，先判断desk是否激活（用于点击地图desk）
      let that = this;
      // console.log("floorsData:", that.floorsData);
      // console.log("id:", id);
      if (ignoreActive) {
        let noData = false;
        that.floorsData.forEach((item, k) => {
          if (item.resourceId === id || item.id === id) {
            // 置灰的按钮不可点击
            that.deskItem = item;
            that.showDeskItem = true;
            that.getPath(item);
          } else {
            console.log("YES");
            noData = true;
          }
        });
        return noData;
      } else {
        that.floorsData.forEach((item, k) => {
          if (item.id === id) {
            console.log("item=====================:", item);
            that.deskItem = item;
            that.showDeskItem = true;
            that.getPath(item);
            if (item.type == "room") {
              //ROOM
              console.log("room!!", item);
              let length = that.roomsStateData.length;
              for (let i = 0; i < length; i++) {
                let jtem = that.roomsStateData[i];
                if (item.resourceId == jtem.resourceId || item.resourceId == jtem.resourceEmail) {
                  console.log("room-jtem:", jtem);
                  if (that.showList) {
                    that.showDeskInfo = false; //显示book it
                  } else {
                    console.log("22222");
                    if (jtem.busy) {
                      that.showDeskInfo = true; //busy为true点击显示按钮组
                    } else {
                      that.showDeskInfo = false; //busy为true点击显示“Book it”
                    }
                  }
                  that.deskItem.busy = jtem.busy;
                  that.deskItem.busy_time = jtem.busy_time;
                  break;
                }
              }
            } else {
              //DESK
              console.log("desk", item);
              // 置灰的desk不可点击
              if (item.active) {
                that.deskItem = item;
                that.showDeskItem = true;
                that.getPath(item);
                let length = that.desksStateData.length;
                for (let i = 0; i < length; i++) {
                  let jtem = that.desksStateData[i];
                  if (item.resourceId == jtem.resourceId || item.resourceId == jtem.resourceEmail) {
                    // console.log("desk-jtem:", jtem);
                    if (jtem.busy) {
                      that.showDeskInfo = true; //busy为true点击显示按钮组
                    } else {
                      // that.showDeskInfo = false;//busy为false点击显示“Book it”
                      that.showDeskInfo = true;
                    }
                    that.deskItem.busy = jtem.busy;
                    break;
                  }
                }
              } else {
                this.showList = true;
                return;
              }
            }
          }
        });
      }
    },
    showLocation(id) {
      let that = this;
      that.floorsData.forEach((item, k) => {
        console.log("item.id:", item.id);
        if (item.resourceId === id || item.id === id) {
          // 置灰的按钮不可点击
          that.deskItem = item;
          that.getPath(item);
        }
      });
    },
    // 等desk和room都加载完才取消loading，需要用到下面方法
    freeBusyReturn(start, end) {
      // this.bus.$emit('loading', true);
      console.log("this.roomResourceIdString:", this.roomResourceIdString);
      console.log(this.showRoomButton);
      if (this.roomResourceIdString && this.showRoomButton) {
        return this.$axios({
          method: this.client == "HPE" || this.client == "HPE Campus" ? "post" : "get",
          url:
            this.client == "HPE" || this.client == "HPE Campus"
              ? "m/v2/spaces/resourceStatus"
              : "m/spaces/resourceStatus",
          // url: 'm/spaces/resourceStatus',
          data: {
            resourceIds: this.roomResourceIdString,
            timeMin: start || this.startTime,
            timeMax: end || this.endTime,
            meetingId: localStorage.meetingIdMap,
            type: "room"
            // token: localStorage.token
          }
        });
      } else {
        return Promise.resolve(null);
      }
    },
    // 2022/1/28
    getSeatsAvaiableReturn(start, end) {
      let that = this;
      let desksDataString = "";
      let length = that.deskData.length;
      for (let i = 0; i < length; i++) {
        let item = that.deskData[i];
        if (item.active && item.resourceId != "" && item.points) {
          desksDataString += item.resourceId + ",";
        }
      }
      desksDataString = desksDataString.substring(0, desksDataString.length - 1);
      if (desksDataString && this.showDeskButton) {
        let meetingId = localStorage.meetingIdMap;
        let email = localStorage.userEmail;
        let after = start || that.startTime;
        let before = end || that.endTime;
        let type = "desk";
        console.log("000000", this.client);
        console.log(
          "1111111111",
          this.client == "HPE" || this.client == "HPE Campus" ? "post" : "get"
        );
        return that.$axios({
          // method: 'GET',
          method: this.client == "HPE" || this.client == "HPE Campus" ? "post" : "get",
          url:
            this.client == "HPE" || this.client == "HPE Campus"
              ? "m/v2/spaces/resourceStatus"
              : "m/spaces/resourceStatus",
          // url: 'm/spaces/resourceStatus',
          data: {
            meetingId: meetingId,
            email: email,
            after: after,
            before: before,
            type: type,
            seatIds: desksDataString
          }
        });
      } else {
        return Promise.resolve(null);
      }
    },

    // getSeatsAvaiableReturn(start, end) {
    //   let that = this;
    //   let desksDataString = "";
    //   let length = that.deskData.length;
    //   for (let i = 0; i < length; i++) {
    //     let item = that.deskData[i];
    //     if (item.active && item.resourceId != "" && item.points) {
    //       desksDataString += item.resourceId + ',';
    //     }
    //   }
    //   if (desksDataString) {
    //     let getDeskDataUrl = "";
    //     getDeskDataUrl = "m/spaces/resourceStatus?";
    //     // getDeskDataUrl = "m/v2/spaces/resourceStatus?";
    //     if (localStorage.meetingIdMap) {
    //       getDeskDataUrl += `meetingId=${localStorage.meetingIdMap}&`;
    //     }
    //     if (localStorage.userEmail) {
    //       getDeskDataUrl += `email=${localStorage.userEmail}&`;
    //     }
    //     getDeskDataUrl += `after=${start || that.startTime}&`;
    //     getDeskDataUrl += `before=${end || that.endTime}&`;
    //     getDeskDataUrl += 'type=' + 'desk&';
    //     desksDataString = desksDataString.substring(0, desksDataString.length - 1)
    //     if (that.floor.resourceId) {
    //       getDeskDataUrl += `seatIds=${desksDataString}&`;
    //     }
    //     return this.get(getDeskDataUrl, {})
    //   } else {
    //     return Promise.resolve(null)
    //   }
    // },

    // 展示日期选择dialog
    showDialogDate() {
      this.dialogDate = true;
    },
    selectDefault() {
      this.notDefault = false;
      this.selectDefaultDialog = true;
    },
    saveDefault() {
      let that = this;
      console.log("====buildingsAndFloorsData =====", this.buildingsAndFloorsData);
      if (that.buildingsAndFloorsData.length == 0) {
        that.selectDefaultDialog = false;
        this.$router.push({ name: "mec" });
        return;
      }
      if (this.selectFloorId == "") {
        // that.$message.error('Please select your default floor.');
        if (that.noFloor) {
          that.selectDefaultDialog = false;
          this.$router.push({ name: "mec" });
          return;
        } else {
          this.$confirm({
            title: "Confirmation",
            message: "Please select your default floor.",
            show: true
          });
        }
      } else {
        // this.$axios({
        //   method: 'post',
        //   url: '/m/campus/userDefaultLocation?token=' + localStorage.token,
        //   data: {
        //     // defaultBuilding: that.buildingName,
        //     // defaultFloor: that.floorName,
        //     // defaultBuilding: that.selectBuildingId,
        //     // defaultFloor: that.selectFloorId,
        //     defaultBuilding: that.selectBuildingId,
        //     defaultFloor: that.selectFloorId,
        //     meetingId: localStorage.meetingIdMap,
        //   }
        // })
        this.$axios({
          method: "post",
          url: "/m/campus/userDefaultLocation",
          data: {
            // defaultBuilding: that.buildingName,
            // defaultFloor: that.floorName,
            // defaultBuilding: that.selectBuildingId,
            // defaultFloor: that.selectFloorId,
            defaultBuilding: that.selectBuildingId,
            defaultFloor: that.selectFloorId,
            meetingId: localStorage.meetingIdMap
          }
        })
          .then(res => {
            console.log(res);
            that.$message.success("Your default office location is updated.");
            that.selectDefaultDialog = false;
            this.getDefaultMap();
            // this.getBuildingsAndFloorsData();
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    defaultChange(building, floor) {
      // this.defaultBuildingId = building.id;
      // this.defaultFloorId = floor.id;
      this.selectBuildingId = building.id;
      this.selectFloorId = floor.id;
      this.buildingName = building.buildingName;
      this.floorName = floor.name;
    },
    getDefaultMap() {
      this.getBuildingsAndFloorsData();
    },
    // getDefaultMap() {
    //   this.bus.$emit('loading', true);
    //   this.getUserLocation().then(res => {
    //     this.buildingName = res.data.data.defaultBuilding;
    //     this.floorName = res.data.data.defaultFloor;
    //     // 传给search 组件
    //     this.bfData = {
    //       buildingName: res.data.data.defaultBuilding,
    //       floorName: res.data.data.defaultFloor
    //     };
    //     this.getBuildingsAndFloorsData();
    //
    //     if (this.buildingName == '' || this.floorName == '') {
    //       this.notDefault = true;
    //     }
    //   }).catch(err => {
    //     this.bus.$emit('loading', false);
    //     console.log(err);
    //   })
    // },
    startInterval() {
      console.log("开始倒计时");
      this.timeout = window.setInterval(() => {
        this.showRefresh = true;
        // this.$confirm({title: "Please Refresh", message: "You've been away for a period of time. Please hit the refresh button to update the map's availability.", show: true});
      }, 2 * 60 * 1000);
    },
    stopInterval() {
      console.log("停止计时");
      if (this.timeout) {
        window.clearInterval(this.timeout);
        this.timeout = null;
      }
    },
    // 键盘选择地图
    MapBlur(e) {
      // 对有颜色的desk和room (floorsDataActiveAndHasState)进行键盘操作选择
      // console.log(document.activeElement.id);
      // 判断当前的focus焦点的对象，只有当canvas被focus的时候才能选择desk(room)
      if (document.activeElement.id != "floorMap") {
        return;
      }
      let that = this;
      console.log("that.floorsDataActiveAndHasState", that.floorsDataActiveAndHasState);
      let dataLength = that.floorsDataActiveAndHasState.length - 1;
      console.log("==== dataLength=====", dataLength);
      if (e.keyCode == 38 || e.keyCode == 40) {
        if (e.keyCode == 38 && that.keydownIndex > 0) {
          that.keydownIndex--;
        } else if (e.keyCode == 40 && that.keydownIndex < dataLength) {
          that.keydownIndex++;
        }
        that.floorsDataActiveAndHasState.forEach((item, index) => {
          if (index === that.keydownIndex) {
            console.log("找到了", item);
            // if (item.id === "wm5h1ck8f7d39l00thak7mup") {
            that.currentItem = item;
            // ROOM
            if (item.type == "room" && item.match == true) {
              if (e.target.cannotClick) {
                return;
              }
              if (that.showList) {
                //true 展示Serch界面 false展示Search list
                // 右侧筛选出来后再点击地图
                that.showDeskInfo = false; //显示book it
                that.showDeskDetail = true;
                let string = that.year + that.startTime;
                let string2 = that.year + that.endTime;
                that.bookingTime = {};
                that.bookingTime.start = moment(string, "YYYYMMM D, h:mm a").unix();
                that.bookingTime.end = moment(string2, "YYYYMMM D, h:mm a").unix();
                that.bookingTime.timeType = "custom";
                console.log("Book it!!!!!!!!!!!!!!!!", item);
                that.getDeskItem(item.id);
              } else {
                console.log("item", item);
                // 右侧没有筛选,直接点击地图
                let string = that.year + that.startTime;
                let string2 = that.year + that.endTime;
                that.bookingTime = {};
                that.bookingTime.start = moment(string, "YYYYMMM D, h:mm a").unix();
                that.bookingTime.end = moment(string2, "YYYYMMM D, h:mm a").unix();
                that.bookingTime.timeType = "custom";
                that.getDeskItem(item.id);

                that.showDeskInfo = true; //显示按钮组
                that.showDeskDetail = true;
              }
              // that.showDeskDetail = true;
              // console.log("startTime!!!:", that.startTime)
              // console.log("endTime!!!:", that.endTime)
              // let string = that.year + that.startTime;
              // let string2 = that.year + that.endTime;
              // that.bookingTime.start = moment(string, "YYYYMMM D, h:mm a").unix();
              // that.bookingTime.end = moment(string2, "YYYYMMM D, h:mm a").unix();
              // that.bookingTime.timeType = 'custom';
              // that.getDeskItem(e.target.id);
            } else if (item.type == "desk") {
              //DESK
              console.log("desk", item);
              if (item.noMatch) {
                return;
              }
              if (that.showList) {
                that.showDeskInfo = false; //显示book it
              } else {
                that.showDeskInfo = true; //显示按钮组
              }
              if (!item.active) {
                return;
              } else {
                console.log(555);
                console.log(item);
                let string = that.year + that.startTime;
                let string2 = that.year + that.endTime;
                that.bookingTime = {};
                that.bookingTime.start = moment(string, "YYYYMMM D, h:mm a").unix();
                that.bookingTime.end = moment(string2, "YYYYMMM D, h:mm a").unix();
                that.bookingTime.timeType = "custom";
                that.getDeskItem(item.id);
                // that.getDeskItem(e.target.id);

                that.showDeskDetail = true;
              }
            }
          }
        });
      }
      // console.log("===============",document.activeElement)
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add " : "Edit ";
    }
  },
  created() {
    let showRoomButton = localStorage.getItem("showRoomButton");
    let showDeskButton = localStorage.getItem("showDeskButton");
    let advanceBookingLimit = localStorage.getItem("advanceBookingLimit");
    let advanceRoomBookingLimit = localStorage.getItem("advanceRoomBookingLimit");
    let config = JSON.parse(localStorage.getItem("config"));
    this.advanceBookingLimit = advanceBookingLimit
      ? Number(advanceBookingLimit)
      : config.advanceBookingLimit;
    this.advanceRoomBookingLimit = advanceRoomBookingLimit
      ? Number(advanceRoomBookingLimit)
      : config.advanceRoomBookingLimit;
    console.log("config", config);
    console.log("advanceBookingLimit", this.advanceBookingLimit);
    if (showRoomButton == "false") {
      this.showRoomButton = false;
    } else {
      this.showRoomButton = true;
    }
    if (showDeskButton == "false") {
      this.showDeskButton = false;
    } else {
      this.showDeskButton = true;
    }
    this.campusName = localStorage.getItem("campusName");
    this.timezone = localStorage.getItem("timezone");
    this.show15Logic = show15;
    // let d = new Date();
    // this.year = d.getFullYear();
    // const start = moment(d);
    // const remainder = (start.minute() % 30);//距离前面整（半）点的分钟
    // const remainder2 = 30 - (start.minute() % 30);//距离后面整（半）点的分钟
    // this.startTime = moment(start).add(-remainder, "minutes").format("MMM D, h:mm a");
    // this.endTime = moment(start).add(remainder2, "minutes").format("MMM D, h:mm a");
    this.client = localStorage.getItem("client");
    if (this.client == "Clorox") {
      this.startTimeDialog = "";
    }
    // Applied Materials || Edwards 预订desk的时候展示按钮组
    if (this.client == "Applied Materials" || this.client == "Edwards Life Sciences") {
      this.deskShowBtn = true;
    }
    this.refreshCurrentDate();
    this.getDefaultMap();
  },

  mounted() {
    let that = this;
    let container = document.getElementById("mapClass");
    container.onmouseout = function(e) {
      let outSide = document.getElementById("spaceContainer");
      outSide.removeEventListener("mousewheel", that.fn);
    };
    // this.getUserLocation();

    window.onresize = () => {
      // console.log('555555',canvas);
      if (canvas) {
        canvas.width = that.$refs.floorMapBlock.offsetWidth;
        // canvas.height = that.$refs.floorMapBlock.offsetHeight;
        // canvas.setHeight(that.$refs.floorMapBlock.offsetHeight);

        // 170是上面campus name的高度加日期和圆点的高度
        let height = that.$refs.mapClass.offsetHeight - 170;
        canvas.height = height;
        canvas.setHeight(height);
        canvas.setWidth(that.$refs.floorMapBlock.offsetWidth);
        canvas.renderAll();
      }
    };
    // window.addEventListener('resize',  this.handleResize())
  },
  destroyed() {
    this.stopInterval();
    window.removeEventListener("keydown", this.MapBlur, true);
  }
};
</script>

<style lang="scss" scoped>
.dateBtn {
  color: #1e88e5;
  cursor: pointer;
  padding: 6px 35px;
  border-radius: 4px;

  &:hover {
    background: #edf6fd;
    opacity: 0.8;
  }
}

.active {
  background: #0a0a0a;
  color: #ffffff;
}

#mapClass {
  padding: 0;
  //min-height: 680px;
  min-height: 580px;
  //height: calc(100vh - 290px);
  height: calc(100vh - 75px);
  display: flex;
  flex-flow: column;

  .mapContent {
    flex: 1;
  }

  .dateTimePicker {
    .dateTimePicker-date {
      height: 65px;
      background: #f3f3f3;
      display: flex;
      justify-content: center;
      align-items: center;

      .btn1 {
        width: 400px;
      }
    }

    .dateTimePicker-operator {
      height: 65px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #4164fc;

      .btn2 {
        width: 190px;
      }

      .btn3 {
        width: 190px;
        color: #4164fc;
      }
    }
  }
}

#floorMap {
  width: 100%;
  height: 500px;
  background: #ffffff;
}

.static {
  /*display: flex;*/
  /*justify-content: center;*/
  /*align-items: center;*/
  text-align: center;
  margin-bottom: 20px;

  .percentage {
    border: 2px solid #4164fd;
    border-radius: 50%;
    width: 55px !important;
    height: 55px !important;
    line-height: 55px !important;
    margin: auto;
  }

  .calendar {
    width: 35px;
    margin: auto;
    display: flex;
    cursor: pointer;
  }

  .static_title {
    padding-top: 10px;
    line-height: 15px;
  }
}

.buildingFloor {
  .buildingName {
    font-size: 1.5rem;
    color: #000000;
    font-weight: bold;
  }

  .floorName {
    font-size: 1rem;
    cursor: pointer;
  }
}

.mapPan {
  position: absolute;
  right: 10%;
  bottom: 10%;

  .calendar {
    width: 35px;
    margin: auto;
    cursor: pointer;
  }
}

.selectColor {
  //color: $main-blue;
}

.canvasHeader {
  background: rgb(243, 243, 243);
  height: 40px;
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: center;
}

.colorCircleContainer {
  margin: 10px auto;
  display: flex;
  justify-content: space-around;
  width: 100%;

  .oneCircle {
    display: flex;
    //align-items: end;
    p {
      margin-right: 5px;
    }

    .circle {
      width: 20px;
      height: 20px;
      border-radius: 50px;
      display: inline-block;
    }

    .circle-bookable {
      background: #64c656;
    }

    .circle-booked {
      background: #ed3b3c;
    }

    .circle-vacant {
      background: #caf3ca;
      border: 2px solid green;
    }

    .circle-occupied {
      background: #f6a4a4;
      //border: 2px solid red;
      border: 2px solid #df2e07;
    }

    .circle-crowded {
      background: purple;
    }

    .circle-free {
      //background: orange;
      background: #ff8c00;
    }

    .circle-unavailable {
      background: gray;
    }
  }
}
</style>
<style scoped>
[role="menu"] {
  min-width: 0px !important;
}
</style>
<!--时间选择器-->
<style lang="scss" scoped>
.checkInPopup {
  .title {
    font-size: 25px;
    background: #ed8933;
    color: #fff;
  }

  .circle {
    width: 250px;
    height: 250px;
    background: #57b74e;
    border-radius: 200px;
    margin: 20px auto;
    font-size: 50px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 60px;
    text-align: center;
  }

  .checkInText {
    background: #f2f2f2;
    color: gray;
    font-size: 15px;
    font-weight: bold;

    .txt {
      padding: 10px auto !important;
    }
  }

  .btnContainer {
    display: flex;
    justify-content: center;
    flex-flow: column;
  }

  .checkInBtn {
    background: gray;
    color: #fff;
    border-radius: 20px;
    margin: 20px auto;
    width: 210px;
  }

  .canCelBtn {
    background: #fff;
    color: gray;
    border: 1px solid gray;
    border-radius: 20px;
    margin-bottom: 20px;
    width: 210px;
  }
}

.vue__time-picker input.display-time {
  height: 3.2em;
  color: #666;
  font-size: 14px;
  border: none;
  border-bottom: 1px solid #949494;
}

.vue__time-picker .controls .char {
  font-size: 29px;
  color: #757575;
}

.v-card__actions {
  flex-wrap: wrap;
}
</style>
